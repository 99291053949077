import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Button,
    CardContent,
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    TextField,
    InputAdornment,
    Pagination,
    Select,
    CircularProgress,
    InputBase,
    MenuItem,
    Dialog,
    DialogContent,
    Divider,
    DialogActions,
    DialogTitle,
    Menu,
    LinearProgress
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { emptyDashboardImg, defaultUserImg } from "./assets";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// Customizable Area End

import AllUsersDashboardController, {
    Props
} from "./AllUsersDashboardController";

export default class AllUsersDashboard extends AllUsersDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    noDataScreen = () => {
        return (
            <EmptyWrapper>
                <Grid container className="gridContainer">
                    <Grid item xs={12}>
                        <Box className="noDataImageBox">
                            <img src={emptyDashboardImg.default} />
                            <Typography className="noDataMainText">Ohh No, No data Found</Typography>
                            <Typography className="noDataNextText">It looks like there’s nothing here yet. Add New User for greater efficiency and growth.</Typography>
                            <Button className="addNewUserButton" data-test-id="create_new_user" onClick={this.navigateToCreateUser}>
                                <Typography className="addNewUserText">Create New User</Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </EmptyWrapper>
        )
    }

    noDataForUserRole = () => {
        return (
            <UserRoleWrapper>
                <Grid container className="gridContainer">
                    <Grid item xs={12}>
                        <Box className="noDataImageBox">
                            <img src={emptyDashboardImg.default} />
                            <Typography className="noDataMainText">Ohh No, No data Found</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </UserRoleWrapper>
        )
    }

    createCompanyDialog = () => {
        return (
            <Dialog
                open={this.state.opencompanyDialog}
                onClose={this.handleCloseCompanyDialog}
            >
                <DialogTitle>
                    <Wrapper>
                        <Typography className="companyDialogTitle">
                            Create Company
                        </Typography>
                        <IconButton
                            aria-label="close"
                            data-test-id="close_create_company"
                            onClick={this.handleCloseCompanyDialog}
                            className="companyDialogClose"
                            sx={{
                                right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                                left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Wrapper>
                </DialogTitle>
                <Divider sx={{ color: "#E2E8F0" }} />
                <DialogContent>
                    <Typography
                        sx={{
                            fontSize: "20px",
                            fontWeight: 700,
                            fontFamily: "Urbanist",
                            padding: "14px 34px",
                            lineHeight: "28px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        Company not found for the current user. Please create a company first.
                    </Typography>

                </DialogContent>
                <Divider />
                <DialogActions>
                    <Wrapper>
                        <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "companyButtonsBox" : "companyButtonsBoxArabic"}`}>
                            <Button
                                color="primary"
                                variant="contained"
                                data-test-id="navigate_to_create_company"
                                onClick={this.navigateToCreateCompany}
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "createCompanyButton" : "createCompanyButtonArabic"}`}
                            >
                                Create Company
                            </Button>
                        </Box>
                    </Wrapper>
                </DialogActions>
            </Dialog>
        )
    }

    candidateTab = () => {
        return (
            <Grid item xs={12} md={4} xl={4}>
                <Button
                    data-test-id="candidate_tab"
                    className={this.state.selectedTab === 'candidate' ? "selectedUsersButton" : "usersButton"}
                    onClick={() => this.handleTabClick('candidate')}
                >
                    Candidates ({this.state.allUsersCount?.candidate_count})
                </Button>
            </Grid>
        )
    }

    employeeTab = () => {
        return (
            <Grid item xs={12} md={4} xl={4}>
                <Button
                    data-test-id="employee_tab"
                    className={this.state.selectedTab === 'employee' ? "selectedUsersButton" : "usersButton"}
                    onClick={() => this.handleTabClick('employee')}
                >
                    Employees ({this.state.allUsersCount?.employee_count})
                </Button>
            </Grid>
        )
    }

    hrSubAdminTab = () => {
        return (
            <Grid item xs={12} md={4} xl={4}>
                <Button
                    data-test-id="hrSubAdmin_tab"
                    className={this.state.selectedTab === 'hr_sub_admin' ? "selectedUsersButton" : "usersButton"}
                    onClick={() => this.handleTabClick('hrSubAdmin')}
                >
                    HR SubAdmin ({this.state.allUsersCount?.hr_sub_admin_count})
                </Button>
            </Grid>
        )
    }

    searchTab = () => {
        return (
            <Grid item xs={12} md={4} xl={4} >
                <TextField
                    className="searchBox"
                    data-test-id="search_box"
                    value={this.state.searchText}
                    placeholder="Search"
                    onChange={this.handleSearch}
                    onKeyUp={this.handleKeyPress}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={this.handleSearchIcon} data-test-id="search_icon">
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
        )
    }

    exportAllTab = () => {
        return (
            <Grid item xs={12} md={4} xl={4}>
                <Button className="exportAllButton" data-test-id="export_all" onClick={this.handleExportAll}>
                    <Typography className="exportAllText">
                        Export All
                    </Typography>
                </Button>
                {this.renderProgressDialog()}
            </Grid>
        )
    }

    renderProgressDialog = () => {
        return (
            <Dialog
                open={this.state.showExportDialog}
                onClose={this.handleCloseExportDialog}
                PaperProps={{
                    sx: {
                        direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
                        overflow: 'hidden',
                        borderRadius: "8px 8px 32px 8px"
                    }
                }}
            >
                <DialogTitle>
                    <Wrapper>
                        <Typography className="companyDialogTitle">
                            Exporting Data
                        </Typography>
                        <IconButton
                            data-test-id="close_export_all"
                            aria-label="close"
                            onClick={this.handleCloseExportDialog}
                            className="companyDialogClose"
                            sx={{
                                right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                                left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Wrapper>
                </DialogTitle>
                <Divider sx={{ color: "#E2E8F0" }} />
                <DialogContent>
                    <Wrapper>
                        <Box className="exportImageBox">
                            <img src={emptyDashboardImg.default} alt="Success Image" height={144} width={144} />
                            <Box width="70%" mt={2}>
                                <LinearProgress
                                    variant="determinate"
                                    value={this.state.exportProgress}
                                    sx={{
                                        height: 10,
                                        borderRadius: 4,
                                        backgroundColor: '#e0e0e0',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: '#059669',
                                        },
                                    }}
                                />
                            </Box>

                            <Typography sx={{
                                color: "#059669",
                                marginTop: 1,
                                fontFamily: "Urbanist",
                                padding: "0px 58px",
                                fontSize: "18px",
                                fontWeight: 700,
                                lineHeight: "26px",

                            }}>                            
                                {this.state.exportProgress}% Complete, Exporting Data
                            </Typography>
                        </Box>
                    </Wrapper>

                    <Typography
                        sx={{
                            fontFamily: "Urbanist",
                            padding: "0px 58px",
                            fontSize: "18px",
                            fontWeight: 400,
                            lineHeight: "26px",
                            color: "#0F172A",
                            textAlign: "center",
                            margin: "12px 0px 20px 0px"
                        }}>
                        Please wait, while we are exporting data. This process may take a few minutes.
                    </Typography>

                </DialogContent>
            </Dialog>
        )
    }

    filterTab = () => {
        return (
            <Grid item xs={12} md={4} xl={4}>
                <Select
                    data-test-id="user_filter"
                    className="filterBox"
                    inputProps={{
                        icon: {
                            up: 0,
                        },
                    }}
                    displayEmpty
                    variant="outlined"
                    value={this.state.selectedFilter}
                    renderValue={
                        this.state.selectedFilter
                            ? undefined
                            : () => <div className="dropDownStyleplaceHolder">Filter</div>
                    }
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={this.handleFilter}
                    input={<InputBase />}
                >
                    {this.state.userFilter.map((option) => (
                        <MenuItem key={option} value={option}
                            sx={{
                                background: `${option === "All Active Users" ? "#f1f5f9" : "none"}`,
                                fontFamily: "Urbanist",
                                fontSize: "14px",
                                fontWeight: 400,
                                margin: "4px"
                            }}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>

        )
    }

    tableOrNoDataRendering = () => {
        return (
            (this.state.selectedTab === 'candidate' && this.state.userData.length > 0) ||
            (this.state.selectedTab === 'employee' && this.state.userData.length > 0) ||
            (this.state.selectedTab === 'hr_sub_admin' && this.state.userData.length > 0)
        )
    }

    dashboardTable = () => {
        return (
            <TableContainer sx={{ mt: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="tableTilteText">Profile</TableCell>
                            <TableCell className="tableTilteText">First Name</TableCell>
                            <TableCell className="tableTilteText">Last Name</TableCell>
                            <TableCell className="tableDataText">Staff ID</TableCell>
                            <TableCell className="tableTilteText">Email</TableCell>
                            <TableCell className="tableTilteText">Contact Number</TableCell>
                            <TableCell className="tableTilteText">Designation</TableCell>
                            <TableCell className="tableTilteText">Department</TableCell>
                            <TableCell className="tableTilteText">Status</TableCell>
                            <TableCell className="tableTilteText">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.userData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Box className="profilePicBox">
                                        <img
                                            src={row.attributes.profile_picture || defaultUserImg.default}
                                            alt="Profile pic"
                                            className={row.attributes.profile_picture ? "profilePicBox" : "tableProfilePic"}
                                        />
                                    </Box>
                                </TableCell>
                                <TableCell className="tableDataText">{row.attributes.first_name}</TableCell>
                                <TableCell className="tableDataText">{row.attributes.last_name}</TableCell>
                                <TableCell className="tableDataText">{row.attributes.staff_id}</TableCell>
                                <TableCell className="tableDataText">{row.attributes.email}</TableCell>
                                <TableCell className="tableDataText">+{row.attributes.full_phone_number}</TableCell>
                                <TableCell className="tableDataText">{row.attributes.designation}</TableCell>
                                <TableCell className="tableDataText">{row.attributes.department.attributes.name}</TableCell>
                                <TableCell className="tableDataText">
                                    <Select
                                        displayEmpty
                                        variant="outlined"
                                        data-test-id="user_status"
                                        className="statusRow"
                                        value={this.state.selectedStatus}
                                        renderValue={
                                            this.state.selectedStatus
                                                ? undefined
                                                : () => (
                                                    <div className="statusDropDown">
                                                        {row.attributes.activated ? "Active" : "Inactive"}
                                                    </div>
                                                )
                                        }
                                        IconComponent={KeyboardArrowDownIcon}
                                        onChange={(event) => this.handleStatus(event, row.id, row.attributes.activated)}
                                        input={<InputBase />}
                                    >
                                        {this.state.userStatus.map((status) => (
                                            <MenuItem
                                                key={status}
                                                value={status}
                                                sx={{
                                                    background: `${status === "Active" ? "#f1f5f9" : "none"}`,
                                                    fontFamily: "Urbanist",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    margin: "4px",
                                                }}
                                            >
                                                {status}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        data-test-id="more_icon"
                                        onClick={(event) => this.handleMenuOpen(event, row.id)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        anchorEl={this.state.anchorEl}
                                        open={Boolean(this.state.anchorEl) && this.state.selectedRowId === row.id}
                                        onClose={this.handleMenuClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        PaperProps={{
                                            sx: {
                                                borderRadius: "8px",
                                                border: "1px solid #E2E8F0",
                                            }
                                        }}
                                        sx={{
                                            mt: 4,
                                            ml: -3,
                                        }}
                                    >
                                        <MenuItem
                                            sx={{ padding: "8px 40px 8px 16px", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 400, color: "#0F172A" }}
                                            onClick={() => this.handleView(row.id)}
                                            data-test-id="view_user"
                                        >
                                            View details
                                        </MenuItem>
                                        <MenuItem
                                            sx={{ padding: "8px 40px 8px 16px", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 400, color: "#0F172A" }}
                                            onClick={() => this.handleEdit(row.id)}
                                            data-test-id="edit_user_details"
                                        >
                                            Edit details
                                        </MenuItem>
                                        <MenuItem
                                            sx={{ padding: "8px 40px 8px 16px", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 400, color: "#F87171", borderRadius: "8px" }}
                                            onClick={() => this.handleDelete(row.id)}
                                            data-test-id="delete_user_details"
                                        >
                                            Delete details
                                        </MenuItem>
                                    </Menu>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    allUsersDashboard = () => {
        return (
            <Wrapper>
                <Card sx={{ borderRadius: 2, boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px" }}>
                    <CardContent>
                        <Grid container spacing={2} alignItems="center" justifyContent="space-between">

                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Grid container spacing={3.5}>
                                    {this.candidateTab()}
                                    {this.employeeTab()}
                                    {this.hrSubAdminTab()}
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Grid container spacing={2}
                                    sx={{
                                        justifyContent: {
                                            xs: "flex-start",
                                            md: "flex-end"
                                        }
                                    }}>
                                    {this.searchTab()}
                                    {this.exportAllTab()}
                                    {this.filterTab()}
                                </Grid>
                            </Grid>
                        </Grid>
                        {this.tableOrNoDataRendering() ?
                            (this.dashboardTable()) :
                            (this.noDataForUserRole()
                            )}
                        {(this.tableOrNoDataRendering() && this.state.totalpage > 1) &&
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                <Pagination
                                    count={this.state.totalpage}
                                    page={this.state.page}
                                    data-test-id="page_change"
                                    onChange={(event, value) => this.handlePageChange(value)}
                                    color="primary"
                                />
                            </Box>
                        }
                    </CardContent>
                </Card>
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="All users" id={this.props.id} navigation={this.props.navigation}>
                <MainWrapper>
                    {!this.state.loader &&
                        <Grid container justifyContent={"center"} spacing={3}>
                            <Grid item xs={11}>
                                <Grid container alignItems={"center"} justifyContent="space-between" spacing={2}>
                                    <Grid item xs={11} sm={5} md={5.2} lg={5.5}>
                                        <Typography className="userTitle">
                                            {!!this.state.allUsersCount?.all_users_count &&
                                                ` All Users(${this.state.allUsersCount?.all_users_count})`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4} xl={3.5}
                                        sx={{
                                            display: "flex",
                                            justifyContent: {
                                                xs: "flex-start",
                                                md: "flex-end",
                                                xl: "flex-end"
                                            },
                                            marginRight: "16px"
                                        }}
                                    >
                                        {!!this.state.allUsersCount?.all_users_count &&
                                            <Button
                                                className="addUserButton"
                                                data-test-id="create_new_user"
                                                onClick={this.navigateToCreateUser}>
                                                <Typography className="addUserText">
                                                    Create New User
                                                </Typography>
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={11}>
                                {this.state.allUsersCount?.all_users_count ?
                                    this.allUsersDashboard() :
                                    this.noDataScreen()}
                            </Grid>
                        </Grid>
                    }
                    {this.state.loader && (
                        <Box className="loader-outer"
                            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box className="loader-inner">
                                <CircularProgress />
                            </Box>
                        </Box>
                    )}
                    {!this.state.companyName && this.createCompanyDialog()}
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    width: "100%",
    "& .usersButton": {
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "26.46px",
        color: "#64748B",
        textTransform: "none",
        padding: "8px",
        paddingLeft: 0,
        "&:hover": {
            background: "none"
        }
    },
    "& .companyDialogClose": {
        color: "#334155",
        position: 'absolute',
        top: 16
    },
    "& .exportImageBox": {
        padding: "10px 0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    "& .tableProfilePic": {
        width: "30px",
        height: "30px"
    },
    "& .companyDialogTitle": {
        lineHeight: "32px",
        color: "#1E1E1E",
        margin: "8px 16px",
        fontSize: "24px",
        fontFamily: "Urbanist",
        fontWeight: 700,
    },
    "& .companyButtonsBox": {
        paddingRight: "16px",
        margin: "16px 0px",
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .companyButtonsBoxArabic": {
        paddingLeft: "16px",
        margin: "16px 0px",
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .createCompanyButtonArabic": {
        opacity: "0px",
        background: "#044352",
        height: "56px",
        gap: "8px",
        borderRadius: "8px",
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: "Urbanist",
        padding: "16px 39.5px 16px 36.5px",
        marginRight: "16px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .createCompanyButton": {
        fontWeight: 700,
        height: "56px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        padding: "16px 24px",
        gap: "8px",
        borderRadius: "8px",
        opacity: "0px",
        marginLeft: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .profilePicBox": {
        height: "56px",
        width: "56px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: '50%',
        border: "1px solid #F0E5FF",
        background: "#CBD5E1"
    },
    "& .selectedUsersButton": {
        paddingLeft: 0,
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "26.46px",
        color: "#64748B",
        textTransform: "none",
        padding: "8px",
        borderRadius: "4px",
        border: "1px solid #64748B",
        background: "#F1F5F9",
    },
    "& .searchBox": {
        width: "100%",
        height: "56px",
        borderRadius: "4px",
        fontFamily: "Urbanist",
        border: "1px solid #94A3B8",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#6C7B89",
        "&:hover": {
            borderColor: "none"
        },
    },
    "& .MuiSelect-icon": {
        transform: 'none !important',
    },
    "& .css-1uwzc1h-MuiSelect-select-MuiInputBase-input": {
        paddingRight: "8px !important"
    },
    "& .statusDropDown": {
        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        color: "#64748B",
        marginTop: "3px"
    },
    "& .statusRow": {
        width: "100px",
        height: "30px",
        borderRadius: "4px",
        padding: "0px 20px 0px 16px",
        textTransform: "none",
        border: "1px solid #CBD5E1",
        color: "#64748B",
        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        marginTop: "3px"
    },
    "& .filterBox": {
        width: "100%",
        height: "55px",
        borderRadius: "4px",
        padding: "0px 20px 0px 16px",
        textTransform: "none",
        background: "#F1F5F9",
        border: "2px solid #94A3B8",
        color: "#64748B"
    },
    "& .filterText": {
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "16px",
        color: "#64748B"
    },
    "& .exportAllButton": {
        width: "100%",
        height: "58px",
        borderRadius: "4px",
        border: "2px solid #94A3B8",
        "&:hover": {
            background: "none"
        }
    },
    "& .exportAllText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textTransform: "none",
        color: "#64748B"
    },
    "& .tableTilteText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#044352"
    },
    "& .tableDataText": {
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#044352"
    }
})
export const EmptyWrapper = styled(Box)({
    width: "100%",
    paddingBottom: "30px",
    height: "calc(100vh - 180px)",
    "& .addNewUserButton": {
        padding: "8px 60px",
        marginTop: "24px",
        height: "58px",
        borderRadius: "4px",
        background: "#044352",
    },
    "& .noDataImageBox": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    "& .noDataNextText": {
        color: "#8A96A8",
        width: "40%",
        fontWeight: 400,
        fontFamily: "Urbanist",
        fontSize: "18px",
        lineHeight: "26px",
        textAlign: "center"
    },
    "& .gridContainer": {
        height: "100%"
    },
    "& .addNewUserText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: "none"
    },
    "& .noDataMainText": {
        color: "#000104",
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "40px",
    },
})

export const UserRoleWrapper = styled(Box)({
    width: "100%",
    paddingBottom: "30px",
    paddingTop: "50px",
    "& .noDataNextText": {
        lineHeight: "26px",
        fontFamily: "Urbanist",
        width: "40%",
        fontWeight: 400,
        color: "#8A96A8",
        fontSize: "18px",
        textAlign: "center"
    },
    "& .addNewUserText": {
        color: "#FFFFFF",
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: "Urbanist",
        lineHeight: "24px",
        textTransform: "none"
    },
    "& .addNewUserButton": {
        padding: "8px 60px",
        marginTop: "24px",
        height: "58px",
        borderRadius: "4px",
        background: "#044352",
    },
    "& .noDataImageBox": {
        height: "100%",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    "& .noDataMainText": {
        color: "#000104",
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "40px",
    },
})
export const MainWrapper = styled(Box)({
    marginTop: "42px",
    paddingBottom: "30px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "& .compMainHeaderGrid": {
        marginTop: "20px"
    },
    "& .addUserButton": {
        height: "58px",
        borderRadius: "4px",
        background: "#044352",
        padding: "17px 48px"
    },
    "& .userTitle": {
        color: "#000104",
        fontWeight: 600,
        fontSize: "25px",
        lineHeight: "30px",
        fontFamily: "Urbanist",
        marginLeft: "16px"
    },
    "& .addUserText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: "none"
    },
})
// Customizable Area End
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
interface UserProfile {
  error?: string;
  data?: {
    id: string;
    type: string;
    attributes: {
      id: string;
      company_name: string;
      "website": string;
      "linkedin": string;
      "twitter": string;
      "country": string;
      "account_id": number;
      "photo": string;
      "full_name": string;
      "date_of_birth": string;
      "email": string;
    }
  }
}
interface SignInResponse {
  meta?: {
    token: string;
    refresh_token: string;
    id: string;
    profile_id: string;
  }
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userProfileData: UserProfile;
  errorMsg: string;
  signInResponse: SignInResponse;
  token: string;
  profileId: string;
  snackbarOpen: boolean;
  snackbarMessage: string;
  snackbarPosition: { top: number; left: number };
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  showUserProfileApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      userProfileData: {},
      errorMsg: "",
      signInResponse: {},
      token: "",
      profileId: "",
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarPosition: { top: 0, left: 0 },
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.showUserProfileApiCallId) {
        this.handleUserProfileApiResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const signInResponse = await getStorageData("signInResponse");
    const parsedSignInResponse = JSON.parse(signInResponse)
    this.setState({
      signInResponse: parsedSignInResponse,
      token: parsedSignInResponse.meta?.token,
      profileId: parsedSignInResponse.meta?.profile_id
    })
    this.showUserProfileApiCall()
  }

  handleUserProfileApiResponse = (responseJson: UserProfile) => {
    if (responseJson && !responseJson.error) {
      this.setState({ userProfileData: responseJson })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  showUserProfileApiCall = () => {
    const headers = {
      "Content-Type": "application/json",
      "token": this.state.token
    };
    const getMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showUserProfileApiCallId = getMsg.messageId;
    getMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showUserProfileApiEndPoint}/${this.state.profileId}`
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getMsg.id, getMsg);
  }
  // Customizable Area End

  // Customizable Area Start
  handleCopyToClipboard = (text: string, event: React.MouseEvent<HTMLDivElement>) => {
    const { top, left, height } = event.currentTarget.getBoundingClientRect();
    const snackbarTop = top + height;
    const snackbarLeft = left;

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text)
        .then(() => {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: 'Email copied to clipboard!',
            snackbarPosition: { top: snackbarTop, left: snackbarLeft }
          });
        })
        .catch((err) => {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: 'Failed to copy email.',
            snackbarPosition: { top: snackbarTop, left: snackbarLeft }
          });
        });
    } else {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: 'Clipboard API not supported.',
        snackbarPosition: { top: snackbarTop, left: snackbarLeft }
      });
    }
  };

  navigateToEditProfile = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EditUserProfile");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }

  navigateToWebsite = () => {
    window?.open(this.state.userProfileData.data?.attributes.website, '_blank')
  }

  navigateToLinkedIn = () => {
    window?.open(this.state.userProfileData.data?.attributes.linkedin, '_blank')
  }

  navigateToTwitter = () => {
    window?.open(this.state.userProfileData.data?.attributes.twitter, '_blank')
  }
  // Customizable Area End

}

import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Button,
    Divider,
    Card,
    CardContent,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    MenuItem,
    Menu,
    Input,
    Select,
    InputBase
} from "@mui/material";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { backArrow, uploadedFile } from "./assets";
// Customizable Area End

import ViewCompetencyController, {
    Props,
} from "./ViewCompetencyController";

export default class ViewCompetency extends ViewCompetencyController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    listingCompetencies = () => {
        return (
            <Wrapper>
                <Box className="viewCompMainBox">
                    <Grid container justifyContent="center" className="viewCompContainer" >
                        <Grid item xs={12} className="companyCardItem">
                            <Card className="viewCompDescBox">
                                <CardContent className="cardContentPadding">
                                    <Grid container>
                                        <Grid item xs={12} className="orgDetailPadding">
                                            <Typography className="cardTitle">
                                                Assistant Specialist
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container justifyContent={"center"}>
                                                <Grid item xs={11}>
                                                    {this.viewCompetencyTable()}
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container justifyContent={"center"}>
                                                <Grid item xs={11}>
                                                    {this.jdTemplates()}
                                                </Grid>
                                            </Grid>
                                        </Grid>


                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper >
        )
    }

    viewCompetencyTable = () => {
        const competencyData = [
            {
                id: "1",
                attributes: {
                    competency_name: "Core Competency",
                    target_level: "Intermediate",
                    competency_type: "Core Competencies",
                    description: "As a Network Engineer in our Network Operation Centre, you will be responsible for.As a Network Engineer in our Network Operation Centre, you will be responsible for."
                },
            },
            {
                id: "2",
                attributes: {
                    competency_name: "Core Competency",
                    target_level: "Intermediate",
                    competency_type: "Core Competencies",
                    description: "As a Network Engineer in our Network Operation Centre, you will be responsible for"
                },
            },
            {
                id: "3",
                attributes: {
                    competency_name: "Core Competency",
                    target_level: "Intermediate",
                    competency_type: "Core Competencies",
                    description: "As a Network Engineer in our Network Operation Centre, you will be responsible for"
                },
            },
        ]
        return (
            <TableContainer>
                <Table sx={{ marginTop: 2, marginBottom: 4, overflowX: "auto" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell className="tableTilteText">Competency Name</TableCell>
                            <TableCell className="tableTilteText">Target Level</TableCell>
                            <TableCell className="tableTilteText">Competency Type</TableCell>
                            <TableCell className="tableTilteText">Description</TableCell>
                            <TableCell className="tableTilteText">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {competencyData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell className="tableDataText" sx={{ width: "208px" }}>
                                    {this.state.isEdit ? (
                                        <Input
                                            fullWidth
                                            value={row.attributes.competency_name}
                                            className="EditTableDataText"
                                            disableUnderline
                                            inputProps={{ style: { padding: '4px' } }}
                                        />
                                    ) : (
                                        row.attributes.competency_name
                                    )}
                                </TableCell>
                                <TableCell className="tableDataText" sx={{ width: "218px" }}>
                                    {this.state.isEdit ? (
                                        <Input
                                            fullWidth
                                            value={row.attributes.target_level}
                                            className="EditTableDataText"
                                            disableUnderline
                                            inputProps={{ style: { padding: '4px' } }}
                                        />
                                    ) : (
                                        row.attributes.target_level
                                    )}
                                </TableCell>
                                <TableCell className="tableDataText" sx={{ width: "260px" }}>
                                    {this.state.isEdit ? (
                                        <Select
                                            data-test-id="competency_type"
                                            className="EditTableDataText"
                                            inputProps={{
                                                icon: {
                                                    left: 0,
                                                },
                                            }}
                                            variant="outlined"
                                            value={row.attributes.competency_type}
                                            displayEmpty
                                            renderValue={
                                                this.state.selectedCompetencyType
                                                    ? undefined
                                                    : () => (
                                                        <div className="dropDownStyleplaceHolder">
                                                            {row.attributes.competency_type}
                                                        </div>
                                                    )
                                            }
                                            IconComponent={KeyboardArrowDownIcon}
                                            input={<InputBase />}
                                        >
                                        </Select>
                                    ) : (
                                        row.attributes.competency_type
                                    )}
                                </TableCell>

                                <TableCell className="tableDataText" sx={{ width: "300px" }}>
                                    {this.state.isEdit ? (
                                        <Input
                                            fullWidth
                                            multiline
                                            minRows={1}
                                            disableUnderline
                                            value={row.attributes.description}
                                            className="EditTableDescDataText"
                                            sx={{ width: "290px" }}
                                        />
                                    ) : (
                                        row.attributes.description
                                    )}
                                </TableCell>

                                <TableCell style={{ width: "100px" }}>
                                    <IconButton
                                        data-test-id="more_icon"
                                        onClick={(event) => this.handleMenuOpen(event, row.id)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        data-test-id="more_menu"
                                        anchorEl={this.state.anchorEl}
                                        open={Boolean(this.state.anchorEl) && this.state.selectedRowId === row.id}
                                        onClose={this.handleMenuClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        PaperProps={{
                                            sx: {
                                                borderRadius: "8px",
                                                border: "1px solid #E2E8F0",
                                            }
                                        }}
                                        sx={{
                                            mt: 4,
                                            ml: -3,
                                        }}
                                    >
                                        <MenuItem
                                            sx={{ padding: "8px 40px 8px 16px", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 400, color: "#0F172A" }}
                                            data-test-id="view_user"
                                        >
                                            View details
                                        </MenuItem>
                                        <MenuItem
                                            sx={{ padding: "8px 40px 8px 16px", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 400, color: "#F87171", borderRadius: "8px" }}
                                            data-test-id="delete_user_details"
                                        >
                                            Delete details
                                        </MenuItem>
                                    </Menu>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    compJdImageGrid = () => {
        return (
            <Grid item xs={12} sm={2} md={1.5} className="jdCompInnerGrid">
                <img
                    src={uploadedFile.default}
                    alt="upload Image"
                    data-test-id="upload_image"
                />
            </Grid>
        )
    }

    compFileNameAndSize = () => {
        return (
            <Grid item xs={12} sm={9} md={9.5} sx={{
                flexDirection: "column",
                display: "flex",
                textAlign: {
                    xs: 'center',
                    sm: 'left',
                },
                alignItems: {
                    xs: 'center',
                    sm: 'flex-start',
                },
            }}>
                <Typography className="jdCompUploadText">
                    document.pdf
                </Typography>
                <Typography className="jdCompUploadSubText">
                    104.20KB
                </Typography>
            </Grid>
        )
    }

    jdTemplates = () => {
        return (
            <Wrapper>
                <Typography className="compLabel">
                    Uploaded Documents <span>*</span>
                </Typography>
                <Box className="jdCompMainBox">
                    <Grid container alignItems={"center"} spacing={1}>
                        {this.compJdImageGrid()}

                        {this.compFileNameAndSize()}
                    </Grid>
                </Box>
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Competencies" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={11}>
                            <Grid container alignItems={"center"} spacing={1}>
                                <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}>
                                    <img src={backArrow.default}
                                        alt="Back Arrow"
                                        className="backArrowImg"
                                        data-test-id="back_to_dashboard"
                                        onClick={this.navigateToDashboard}
                                    />
                                </Grid>
                                <Grid item xs={10.5} sm={5} md={5.2} lg={5.5}>
                                    <Typography className="jobTitle">
                                        Job Competency
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={6} lg={5} className="editJobMainBox">
                                            <Button
                                                className="deleteButton"
                                                data-test-id="delete_competency"
                                            >
                                                <Typography className="deleteText">
                                                    Delete
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={5} className="editJobMainBox">
                                            <Button
                                                className="editJobeButton"
                                                data-test-id="edit_job_competency"
                                                onClick={this.EditJobCompetency}
                                            >
                                                <Typography className="editJobText">
                                                    Edit Job Competency
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                            {this.listingCompetencies()}
                        </Grid>

                    </Grid>
                </MainWrapper >
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    width: "100%",
    "& .viewCompMainBox": {
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    "& .viewCompContainer": {
        width: "100%",
        padding: "0px 8px",
        scrollbarWidth: "none",
        overflowX: "auto",
        scrollSnapType: "x mandatory",
        scrollBehavior: "smooth",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    "& .viewCompDescBox": {
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        margin: "60px 5vw",
        width: "65vw",
        "@media(max-width:900px)": {
            width: "80vw"
        },
    },
    "& .cardContentPadding": {
        padding: "0px 0px 42px 0px"
    },
    "& .tableDataText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#044352"
    },
    "& .EditTableDataText": {
        width: "208px",
        height: "66px",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        padding: "8px",
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#044352"
    },
    "& .EditTableDescDataText": {
        width: "300px",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        padding: "8px",
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#044352"
    },
    "& .cardTitle": {
        color: "#0444352",
        fontFamily: "Urbanist",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "24px"
    },
    "& .dividerPadding": {
        padding: "0px 56px 0px 35px"
    },
    "& .jdCompMainBox": {
        borderRadius: "8px",
        display: "flex",
        border: "1px solid #CBD5E1",
        alignItems: "center",
        padding: "20px 5px"
    },
    "& .tableTilteText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#044352"
    },
    "& .deleteDocImg": {
        display: "flex",
        justifyContent: "center"
    },
    "& .compLabel": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        linHheight: "24px",
        color: "#344054",
        marginBottom: "10px"
    },
    "& .jdCompUploadSubText": {
        fontWeight: 400,
        fontFamily: "Urbanist",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#64748B"
    },
    "& .jdCompInnerGrid": {
        display: "flex",
        justifyContent: "center"
    },
    "& .jdCompUploadText": {
        lineHeight: "24px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 400,
        color: "#0F172A"
    },
})
export const MainWrapper = styled(Box)({
    width: "100%",
    marginTop: "42px",
    paddingBottom: "30px",
    display: "flex",
    justifyContent: "center",
    "& .backArrowImg": {
        paddingRight: "12px"
    },
    "& .jobTitle": {
        fontWeight: 600,
        fontSize: "25px",
        lineHeight: "30px",
        fontFamily: "Urbanist",
        color: "#000104",
        marginLeft: "12px"
    },
    "& .editJobMainBox": {
        display: "flex",
        justifyContent: "flex-end",
        "@media(max-width:600px)": {
            justifyContent: "flex-start",
            marginTop: "10px"
        }
    },
    "& .deleteButton": {
        height: "58px",
        padding: "10px 25px",
        borderRadius: "4px",
        width: "100%",
        border: "2px solid #94A3B8",
        "&:hover": {
            background: "none"
        },
        "@media(max-width:599px)": {
            width: "60%"
        }
    },
    "& .orgDetailPadding": {
        padding: "30px 56px 30px 35px"
    },
    "& .deleteText": {
        fontFamily: "Urbanist",
        fontWeight: 700,
        lineHeight: "24px",
        fontSize: "16px",
        color: "#044352",
        textTransform: "none"
    },
    "& .editJobeButton": {
        width: "100%",
        padding: "10px 25px",
        borderRadius: "4px",
        height: "58px",
        border: "1px solid #94A3B8",
        background: "#044352",
        "@media(max-width:599px)": {
            width: "60%"
        }
    },
    "& .editJobText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: "none"
    },
})
// Customizable Area End
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import Backend from 'i18next-http-backend';
import en from "../public/locals/en/translation.json";
import ar from "../public/locals/ar/translation.json";
// Translation files
const resources = {
  en: {
    translation: en,
  },
  ar: {
    translation: ar,
  },
};
i18n
//   .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en', // Default language
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
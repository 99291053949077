import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData , removeStorageData} from '../../../framework/src/Utilities';
import { SelectChangeEvent } from '@mui/material';

import React from "react";

interface UserProfile {
  data?: {
    id: string;
    type: string;
    attributes: {
      id: string;
      company_name: string;
      "photo": string;
      "full_name": string;
      "email": string;
      "linkedin": string | null;
      "twitter": string | null;
      "website": string | null;
      "date_of_birth": string | null;
    }
  }
}

interface DeleteProfilePhoto {
  error?: string;
  message?: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  closeLogout: () => void ;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  profileSettingScreen: boolean;
  notificationSettingScreen: boolean;
  updateNotification: boolean;
  logoutProfile:boolean;
  deleteAccount:boolean;
  deleteSuccess: boolean;
  changePassword:boolean;
  authToken: string;
  newPasswords: string;
  confirmPasswords: string;
  currentPassword: string;
  currentPasswordResponse:boolean;
  ChangedOpen:boolean;
  profileId:string;
  userProfileData: UserProfile;
  deleteProfilePhoto: DeleteProfilePhoto;
  imageRef: React.RefObject<HTMLInputElement>;
  imageUpload: FileList | null;
  profileImage: string[];
  referenceImage: string;
  languageSettingScreen: boolean;
  languageChange: string[];
  selectedLanguage: string;
  languageChangeSuccess: boolean;
  rolespermissionSettingScreen: boolean;
  activeTab: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  forgetPasswordApiCall: string = "";
  getCurrentPassApiCallId: string = "";
  showUserProfileApiCallId: string ="";
  getUserAccountDeleteApiCallId: string ="";
  deleteProfilePhotoApiCallId: string ="";
  updateProfilePhotoApiCallId: string = "";
  languageChangeApiCallId: string ="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      profileSettingScreen: true,
      referenceImage: "",
      notificationSettingScreen: false,
      updateNotification:false,
      logoutProfile:false,
      deleteAccount:false,
      deleteSuccess:false,
      changePassword:false,
      imageUpload: null,
      profileImage: [],
      authToken: "",
      newPasswords: "",
      confirmPasswords: "",
      currentPassword: "",
      currentPasswordResponse:false,
      ChangedOpen:false,
      profileId:"",
      userProfileData: {},
      deleteProfilePhoto:{}, 
      imageRef: React.createRef(),
      languageSettingScreen:false,
      languageChange:["English" , "Arabic"],
      selectedLanguage: "English",
      languageChangeSuccess: false,
      rolespermissionSettingScreen:false,
      activeTab: 'HR SubAdmin'
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.forgetPasswordApiCall){
    }
    if(apiRequestCallId === this.getCurrentPassApiCallId){

      this.setState({currentPasswordResponse:responseJson.matched})
    }
    if (apiRequestCallId === this.showUserProfileApiCallId) {
      this.handleUserProfileApiResponse(responseJson)
    }
    if (apiRequestCallId === this.deleteProfilePhotoApiCallId) {
      this.handleDeletePhotoApiResponse(responseJson)
    }
    if(apiRequestCallId === this.updateProfilePhotoApiCallId){
    }
    if(apiRequestCallId === this.languageChangeApiCallId){
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    const tokenMeta = await getStorageData('signInResponse', true);
    this.setState({
      authToken: tokenMeta?.meta?.token,
      profileId: tokenMeta?.meta?.profile_id
    })
    this.showUserProfileApiCall()
    this.setState({ activeTab: "HRSubadmin" });
  }

  apiResetPasswords = () => {
    const headers = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const httpBody: any = {
      "password": this.state.newPasswords,
      "password_confirmation": this.state.confirmPasswords

    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.forgetPasswordApiCall = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.resetPasswordEndPoint}?token=${this.state.authToken}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resetApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  apiCurrentPasswordGetCall = (currentPassword: string) => {
    const headers = {
      "token": this.state.authToken,
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCurrentPassApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCurrentPassApiEndPoint}=${currentPassword}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  showUserProfileApiCall = () => {
    const headers = {
      "Content-Type": "application/json",
      "token": this.state.authToken
    };
    const getMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showUserProfileApiCallId = getMsg.messageId;
    getMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showUserProfileApiEndPoint}/${this.state.profileId}`
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getMsg.id, getMsg);
  }

  userAccountDeleteGetCall = () => {
    
    const headers = {
      "token": this.state.authToken,
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserAccountDeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserAccountDeleteAPiEndPoint}/${this.state.profileId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationDeleteApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteProfilePhotoApiCall = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteProfilePhotoApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteProfilePhotoApiEndpoint}/${this.state.profileId}/delete_photo`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationDeleteApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  languageChangeApiCall = () => {
    const headers = {
        "token": this.state.authToken
    };
    const getUserStatusMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.languageChangeApiCallId = getUserStatusMsg.messageId;
    getUserStatusMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );
    getUserStatusMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.languageChangeApiEndPoint}=${this.state.selectedLanguage === "English" ? "en" : "ar"}`
    );
    getUserStatusMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PATCH"
    );
    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
}

  handleUserProfileApiResponse = (responseJson: UserProfile) => {
    if (responseJson) {
      this.setState({ userProfileData: responseJson })
    }
  }

  handleDeletePhotoApiResponse = (responseJson: DeleteProfilePhoto) => {
    if (responseJson && !responseJson.error) {
      
      if(this.state.imageUpload){
        this.setState({
          profileImage: [], imageUpload: null
        })
      }
      this.setState({ deleteProfilePhoto: responseJson },() => {this.showUserProfileApiCall()})
    }
  }

  handleImage = () => {
    return this.state.imageRef.current ? this.state.imageRef.current.click() : ""
  }

  updateProfilePhotoApiCall = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateProfilePhotoApiCallId = requestMessage.messageId;
    if(this.state.imageUpload && this.state.imageUpload?.length > 0){
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          "profile": {
            "full_name": this.state.userProfileData.data?.attributes.full_name,
            "date_of_birth": this.state.userProfileData.data?.attributes.date_of_birth,
            "company_name": this.state.userProfileData.data?.attributes.company_name,
            "website": this.state.userProfileData.data?.attributes.website,
            "linkedin": this.state.userProfileData.data?.attributes.linkedin,
            "twitter": this.state.userProfileData.data?.attributes.twitter,
            "photo": this.state.referenceImage
          }
        })
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateUserProfileApiEndpoint}/${this.state.profileId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleImageChange= (files: FileList | null) => {
    if(files){
      this.setState({
        profileImage: [URL.createObjectURL(files[0])],
        imageUpload: files
      })
      const fileReader = new FileReader();

      fileReader.onload = () => {
        this.setState({referenceImage: fileReader.result as string}, () => this.updateProfilePhotoApiCall())
      };

      fileReader.readAsDataURL(files[0]);
    }
  }

  removeProfilePhoto = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();
     this.deleteProfilePhotoApiCall();
  }

  navigateToDashboard = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);    
  }

  profileScreen = () => {
    this.setState({profileSettingScreen : true ,notificationSettingScreen : false ,
       languageSettingScreen:false , rolespermissionSettingScreen:false})
  }

  notificationScreen = () => {
    this.setState({notificationSettingScreen : true, profileSettingScreen : false ,
       languageSettingScreen:false , rolespermissionSettingScreen:false})
  }

  closeNotificationPopupModal = () => {
    this.setState({updateNotification:false});
  };
  
  openNotificationPopupModal = () => {
    this.setState({updateNotification:false});
  };

  handleUpdateNotification = () => {
    this.setState({updateNotification:true});
  }

  closeLogoutProfilePopupModal = () => {
    this.setState({logoutProfile:false});
  };
  
  openLogoutProfilePopupModal = () => {
    removeStorageData("signInResponse")
    this.navigateToSignIn()
    this.setState({logoutProfile:false});
  };

  handleLogoutProfile = () => {
    this.setState({logoutProfile:true});
  }

  navigateToSignIn = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);    
  }

  closeDeleteAccountPopupModal = () => {
    this.setState({deleteAccount:false});
  };
  
  openDeleteAccountPopupModal = () => {
    this.handleDeleteSuccess()
    this.setState({deleteAccount:false});
  };

  handleDeleteAccount = () => {
    this.setState({deleteAccount:true});
  }

  closeDeleteSuccessPopupModal = () => {
    this.setState({deleteSuccess:false});
  };
  
  openDeleteSuccessPopupModal = () => {
    this.setState({deleteSuccess:false});
    this.navigateToEmailLogin();
  };

  handleDeleteSuccess = () => {
    this.userAccountDeleteGetCall();
    this.setState({deleteSuccess:true});
  }

  closeChangePasswordPopupModal = () => {
    this.setState({changePassword:false});
  };
  
  openChangePasswordPopupModal = (password :{currentPassword: string;
    newPassword: string;
    confirmPassword: string
  }) => {
    this.setState({currentPassword:password.currentPassword ,  newPasswords:password.newPassword , confirmPasswords:password.confirmPassword}, () =>this.apiResetPasswords());
    this.setState({ChangedOpen : true ,changePassword:false })

  };
  
  handleChangePassword = () => {
      this.setState({changePassword:true});

  }

  closeChangedModal = () => {
    this.setState({ChangedOpen : false})
  }
  
  openChangedModal = () => {
    this.navigateToEmailLogin()
  }

  navigateToEmailLogin = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);    
  }

  languageScreen = async() => {
    const language = await getStorageData("selectedLanguage");
    this.setState({languageSettingScreen: true , profileSettingScreen:false,
       notificationSettingScreen:false,rolespermissionSettingScreen:false, selectedLanguage: language})
  }

  handleLanguageChange = (event: SelectChangeEvent<string>) => {
    this.setState({ selectedLanguage: event.target.value });
  };

  closelanguageChangeModal = () => {
    this.setState({languageChangeSuccess : false})
  }

  openlanguageChangeModal = () => {
    this.setState({languageChangeSuccess : false})
  }

  handlelanguageChangeSuccess = () => {
    this.languageChangeApiCall();
    localStorage.setItem('selectedLanguage', this.state.selectedLanguage);
    this.setState({languageChangeSuccess : true})
  }

  rolesPermissionScreen = () => {
    this.setState({rolespermissionSettingScreen : true , languageSettingScreen: false , 
      profileSettingScreen:false, notificationSettingScreen:false})
  }

  handleHRGubAdminSaveSettings = () => {
    this.setState({ activeTab: "ManageEmployeeData" }); 
  };
  handleManageEmployeeSaveSettings = () => {
    this.setState({ activeTab: "ManageCandidates" }); 
  };
  handleTabClick = (tabName:string) => {
    this.setState({ activeTab: tabName });
  };
  // Customizable Area End
}

export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const arrow_Left = require("../assets/arrow_Left.svg");
export const drop_Down = require("../assets/dropDown.svg");
export const progress = require("../assets/progress.svg");
export const calendar = require("../assets/calendar.svg");
export const closeIcon = require("../assets/closeIcon.svg");
export const right = require("../assets/rightIcon.png");
export const deleteIcon = require("../assets/delete.png");


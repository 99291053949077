import React from "react";

// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Button
} from "@mui/material";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import { emptyDashboardImg } from "./assets";
// Customizable Area End

import CompetencyDashboardController, {
    Props,
} from "./CompetencyDashboardController";

export default class CompetencyDashboard extends CompetencyDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    noDataScreen = () => {
        return (
            <EmptyWrapper>
                <Grid container className="gridContainer">
                    <Grid item xs={12}>
                        <Box className="compNoDataImageBox">
                            <img src={emptyDashboardImg.default} />
                            <Typography className="compNoDataNextText">
                                Competencies help you identify the skills and behaviors necessary for different job roles. Start by creating a new competency that aligns with your organization's goals. Click 'Create Competency' to get started.
                            </Typography>
                            <Button
                                className="createCompetencyButton"
                                data-test-id="create_competency"
                                onClick={this.navigateToCreateComp}>
                                <Typography className="compCreateText">
                                    Create Competency
                                </Typography>
                            </Button>
                            <Button
                                className="createCompDictionaryButton"
                                data-test-id="create_competency_dictionary">
                                <Typography className="compDectionaryText">
                                    Create Competency Dictionary
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </EmptyWrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Competencies" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={11}>

                            <Grid container spacing={1} className="compMainHeaderGrid">
                                <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                                    {this.noDataScreen()}
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const EmptyWrapper = styled(Box)({
    width: "100%",
    paddingBottom: "30px",
    height: "calc(100vh - 180px)",
    "& .createCompetencyButton": {
        marginTop: "24px",
        padding: "8px 90px",
        height: "58px",
        maxWidth: "400px",
        borderRadius: "4px",
        background: "#044352",
    },
    "& .createCompDictionaryButton": {
        marginTop: "24px",
        padding: "8px 50px",
        maxWidth: "400px",
        height: "58px",
        borderRadius: "4px",
        border: "1px solid #94A3B8"
    },
    "& .compNoDataImageBox": {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .compNoDataNextText": {
        lineHeight: "26px",
        color: "#8A96A8",
        width: "50%",
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "18px",
        textAlign: "center"
    },
    "& .gridContainer": {
        height: "100%"
    },
    "& .compDectionaryText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#000104",
        textTransform: "none"
    },
    "& .compNoDataMainText": {
        fontFamily: "Urbanist",
        color: "#000104",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "40px",
    },
    "& .compCreateText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: "none"
    }
})

export const MainWrapper = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginTop: "42px",
    paddingBottom: "30px",
    "& .backArrowImg": {
        paddingRight: "12px"
    },
    "& .compTitle": {
        color: "#000104",
        marginLeft: "12px",
        fontWeight: 600,
        fontFamily: "Urbanist",
        fontSize: "25px",
        lineHeight: "30px"
    },
    "& .compMainHeaderGrid": {
        marginTop: "20px"
    },
})
// Customizable Area End
import React from "react";
// Customizable Area Start    
import {
    Box,
    Grid,
    styled,
    Button,
    Typography,
    Card,
    CardContent,
    Input,
    TextField,
    Select,
    MenuItem,
    InputBase,
    InputAdornment,
    IconButton,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import { backArrow, camera, successImg } from "./assets";
export const configJSON = require("./config");
// Customizable Area End

import UserCreationController, {
    Props,
} from "./UserCreationController";

export default class UserCreation extends UserCreationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    successUserCreationDialog = () => {
        return (
            <Dialog
                open={this.state.openSuccessUser}
                onClose={this.handleCloseSuccessUser}
                PaperProps={{
                    sx: {
                        direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
                        borderRadius: "8px 8px 32px 8px",
                        overflow: 'hidden',
                    }
                }}
            >
                <DialogTitle>
                    <Wrapper>
                        <Typography className="successUserDialogTitle">
                            {this.state.isEdit ? "User Profile Updated" : "New User Profile Created"}
                        </Typography>
                        <IconButton
                            aria-label="close"
                            data-test-id="close_user_success"
                            onClick={this.handleCloseSuccessUser}
                            className="successUserDialogClose"
                            sx={{
                                right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                                left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Wrapper>
                </DialogTitle>
                <Divider sx={{ color: "#E2E8F0" }} />
                <DialogContent>
                    <Wrapper>
                        <Box className="successUserImageBox">
                            <img src={successImg.default} alt="Success Image" height={144} width={144} />
                        </Box>
                    </Wrapper>
                    <Typography
                        sx={{
                            fontSize: "20px",
                            fontWeight: 700,
                            fontFamily: "Urbanist",
                            padding: "14px 34px",
                            lineHeight: "28px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        {this.state.isEdit ?
                            "Profile Updated Successfully!" :
                            "Profile Created Successfully!"
                        }
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            lineHeight: "26px",
                            fontFamily: "Urbanist",
                            padding: "0px 40px",
                            fontSize: "18px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        {this.state.isEdit ?
                            "The user profile has been updated successfully. The user will receive an email with login credentials and further instructions." :
                            "The new user profile has been created successfully. The user will receive an email with login credentials and further instructions."
                        }
                    </Typography>

                </DialogContent>
                <Divider />
                <DialogActions>
                    <Wrapper>
                        <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "viewDetailButtonsBox" : "viewDetailButtonsBoxArabic"}`}>
                            <Button
                                color="primary"
                                variant="contained"
                                data-test-id="navigate_to_view_user"
                                onClick={this.navigateToViewUserProfile}
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "viewDetailButton" : "viewDetailButtonArabic"}`}
                            >
                                View
                            </Button>
                        </Box>
                    </Wrapper>
                </DialogActions>
            </Dialog>
        )
    }

    userFirstName = () => {
        return (
            <Wrapper>
                <Typography className="userInputLabel">
                    First Name  <span>*</span>
                </Typography>
                <Input
                    data-test-id="first_name"
                    className="userEnterInputField"
                    disableUnderline
                    value={this.state.firstName}
                    onChange={(event) => this.handleFirstName(event)}
                    placeholder="First name"
                    autoComplete="off"
                />
                <Typography className="errorStyle">
                    {this.state.createUserClicked && !this.state.firstName && configJSON.errorText}
                    {this.state.firstName && this.state.firstNameError}
                </Typography>
            </Wrapper>
        )
    }

    userLastName = () => {
        return (
            <Wrapper>
                <Typography className="userInputLabel">
                    Last Name  <span>*</span>
                </Typography>
                <Input
                    data-test-id="last_name"
                    className="userEnterInputField"
                    disableUnderline
                    value={this.state.lastName}
                    onChange={this.handleLastName}
                    placeholder="Last name"
                    autoComplete="off"
                />
                <Typography className="errorStyle">
                    {this.state.createUserClicked && !this.state.lastName && configJSON.errorText}
                    {this.state.lastName && this.state.lastNameError}
                </Typography>
            </Wrapper>
        )
    }

    userEmail = () => {
        return (
            <Wrapper>
                <Typography className="userInputLabel">
                    Email  <span>*</span>
                </Typography>
                <Input
                    data-test-id="user_email"
                    className="userEnterInputField"
                    disableUnderline
                    value={this.state.userEmail}
                    onChange={(event) => this.handleUserEmailChange(event)}
                    placeholder="you@gmail.com"
                    autoComplete="off"
                />
                <Typography className="errorStyle">
                    {this.state.createUserClicked && !this.state.userEmail && configJSON.errorText}
                    {this.state.userEmail && this.state.emailError}
                </Typography>
            </Wrapper>
        )
    }

    userContactNumber = () => {
        return (
            <Wrapper>
                <Typography className="userInputLabel">
                    Contact Number <span>*</span>
                </Typography>
                <TextField
                    fullWidth
                    data-test-id="contact_number"
                    variant="outlined"
                    placeholder="(555) 000-0000"
                    value={this.state.userContactNumber}
                    onChange={this.handlePhoneNumberChange}
                    sx={{
                        lineHeight: "20px",
                        '& .MuiOutlinedInput-root': {
                            "borderRadius": "8px",
                            '&:focus-visible': {
                                border: "none"
                            },
                            '&:hover': {
                                border: "none",
                                outline: "none"
                            },
                        },
                        "& .MuiSelect-icon": {
                            transform: 'none !important',
                        },
                        "& .MuiOutlinedInput-input": {
                            padding: "14px 16px"
                        },
                        "& .MuiSelect-select-MuiInputBase-input-MuiInput-input:focus": {
                            backgroundColor: "none",
                            border: "1px solid lightgray"
                        },
                        "& .MuiInputBase-root-MuiOutlinedInput-root": {
                            borderRadius: "8px"
                        },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Select
                                    data-test-id="country_code"
                                    className="countryCodeText"
                                    value={this.state.selectedCountryCode}
                                    onChange={this.handleCountryCodeChange}
                                    variant="standard"
                                    IconComponent={(props) => <IconButton {...props} sx={{ padding: 0 }}><ExpandMoreIcon /></IconButton>}
                                    disableUnderline
                                    sx={{ minWidth: 70 }}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: 48 * 4,
                                                width: 'auto',
                                                overflowY: 'auto',
                                                marginLeft: "-10px"
                                            },
                                        },
                                    }}
                                >
                                    {this.state.countryCodeData.data?.map((code) => (
                                        <MenuItem key={code.country_name} value={`${code.country_code}`}>
                                            {code.country_code}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </InputAdornment>
                        ),
                    }}
                />
                <Typography className="errorStyle">
                    {this.state.createUserClicked && !this.state.userContactNumber && configJSON.errorText}
                    {this.state.userContactNumber && this.state.contactError}
                </Typography>
            </Wrapper>
        )
    }

    userStaffId = () => {
        return (
            <Wrapper>
                <Typography className="userInputLabel">
                    Staff ID <span>*</span>
                </Typography>
                <Input
                    data-test-id="user_staff_id"
                    className="userEnterInputField"
                    disableUnderline
                    placeholder="Staff Id"
                    value={this.state.staffId}
                    onChange={this.handleStaffIdChange}
                    autoComplete="off"
                />
                <Typography className="errorStyle">
                    {this.state.createUserClicked && !this.state.staffId && configJSON.errorText}
                    {this.state.staffId && this.state.staffIdError}
                </Typography>
            </Wrapper>
        )
    }

    userBirthDate = () => {
        return (
            <Wrapper>
                <Typography className="userInputLabel">
                    Date of Birth <span>*</span>
                </Typography>
                <TextField
                    type="date"
                    inputRef={this.dateInputRef}
                    data-test-id="date_of_birth"
                    className="birthDateField"
                    value={this.state.userBirthDate}
                    onChange={this.handleBirthDateChange}
                    onBlur={this.birthDateValidation}
                    InputLabelProps={{
                        shrink: true,
                        sx: { color: this.state.userBirthDate ? 'lightgray' : '#aaa' },
                    }}
                    placeholder="Date of Birth"
                    autoComplete="off"
                    inputProps={{
                        max: this.getMaxDateOfBirthDate()
                    }}
                    sx={{
                        '& .MuiInputBase-input': {
                            border: 'none',
                            fontFamily: 'Urbanist',
                            fontSize: '16px',
                            fontWeight: 400,
                            padding: '12px 16px',
                            color: "rgba(15, 23, 42, 1)"
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none',
                            },
                            '&:hover fieldset': {
                                border: 'none',
                            },
                        },
                    }}
                />
                <Typography className="errorStyle">
                    {this.state.createUserClicked && !this.state.userBirthDate && configJSON.errorText}
                    {this.state.userBirthDate && this.state.birthDateError}
                </Typography>
            </Wrapper>
        )
    }

    userGender = () => {
        return (
            <Wrapper>
                <Typography className="userInputLabel">
                    Gender <span>*</span>
                </Typography>
                <Select
                    data-test-id="user_gender"
                    className="genderDropDown"
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    displayEmpty
                    variant="outlined"
                    value={this.state.selectedGender}
                    onChange={this.handleGenderChange}
                    renderValue={
                        this.state.selectedGender
                            ? undefined
                            : () => (
                                <div className="dropDownplaceHolder">
                                    Choose Gender
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    input={<InputBase />}
                >
                    {this.state.gender.map((option) => (
                        <MenuItem key={option} value={option}
                            sx={{
                                fontFamily: "Urbanist",
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "22px",
                                margin: "4px",
                                borderRadius: "8px",
                            }}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
                <Typography className="errorStyle">
                    {this.state.createUserClicked && !this.state.selectedGender && configJSON.errorText}
                </Typography>
            </Wrapper>
        )
    }

    userDesignation = () => {
        return (
            <Wrapper>
                <Typography className="userInputLabel">
                    Designation <span>*</span>
                </Typography>
                <Input
                    data-test-id="user_designation"
                    className="userEnterInputField"
                    disableUnderline
                    placeholder="Enter Designation"
                    value={this.state.userDesignation}
                    onChange={this.handleDesignationchange}
                    autoComplete="off"
                />
                <Typography className="errorStyle">
                    {this.state.createUserClicked && !this.state.userDesignation && configJSON.errorText}
                </Typography>
            </Wrapper>
        )
    }

    userDepartment = () => {
        return (
            <Wrapper>
                <Typography className="userInputLabel">
                    Department <span>*</span>
                </Typography>
                <Select
                    className="genderDropDown"
                    data-test-id="user_department"
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    variant="outlined"
                    displayEmpty
                    value={this.state.selectedDepartment}
                    onChange={this.handleDepartmentChange}
                    renderValue={
                        this.state.selectedDepartment
                            ? undefined
                            : () => (
                                <div className="dropDownplaceHolder">
                                    Choose Department
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    input={<InputBase />}
                >
                    {this.state.department.data?.map((option) => (
                        <MenuItem key={option.id} value={option.id}
                            sx={{
                                fontFamily: "Urbanist",
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "22px",
                                margin: "4px",
                                borderRadius: "8px",
                            }}>
                            {option.attributes?.name}
                        </MenuItem>
                    ))}
                </Select>
                <Typography className="errorStyle">
                    {this.state.createUserClicked && !this.state.selectedDepartment && configJSON.errorText}
                </Typography>
            </Wrapper>
        )
    }

    userCompany = () => {
        return (
            <Wrapper>
                <Typography className="userInputLabel">
                    Company <span>*</span>
                </Typography>
                <Input
                    disableUnderline
                    data-test-id="user_company"
                    className="userEnterInputField"
                    placeholder="Company Name"
                    value={this.state.companyName}
                    onChange={this.handleCompanyNameChange}
                    autoComplete="off"
                />
                <Typography className="errorStyle">
                    {this.state.createUserClicked && !this.state.companyName && configJSON.errorText}
                </Typography>
            </Wrapper>
        )
    }

    userType = () => {
        return (
            <Wrapper>
                <Typography className="userInputLabel">
                    User Type <span>*</span>
                </Typography>
                <Select
                    data-test-id="user_type"
                    className="genderDropDown"
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    displayEmpty
                    variant="outlined"
                    value={this.state.selectedUserType}
                    onChange={this.handleUserChange}
                    renderValue={
                        this.state.selectedUserType
                            ? undefined
                            : () => (
                                <div className="dropDownplaceHolder">
                                    Choose user type
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    input={<InputBase />}
                >
                    {this.state.userType.map((option) => (
                        <MenuItem key={option} value={option}
                            sx={{
                                fontFamily: "Urbanist",
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "22px",
                                margin: "4px"
                            }}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
                <Typography className="errorStyle">
                    {this.state.createUserClicked && !this.state.selectedUserType && configJSON.errorText}
                </Typography>
            </Wrapper>
        )
    }

    userCompetencies = () => {
        return (
            <Wrapper>
                <Typography className="userInputLabel">
                    Competencies <span>*</span>
                </Typography>
                <Select
                    className="genderDropDown"
                    data-test-id="user_competencies"
                    multiple
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    displayEmpty
                    variant="outlined"
                    value={this.state.selectedCompetencies}
                    onChange={this.handleCompetenciesChange}
                    renderValue={(selected) =>
                        selected.length > 0 ? selected.join(', ') : (
                            <div className="dropDownplaceHolder">
                                Choose Competencies
                            </div>
                        )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    input={<InputBase />}
                >
                    {this.state.competencies.map((item) => (
                        <MenuItem key={item} value={item}
                            sx={{
                                fontFamily: "Urbanist",
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "22px",
                                margin: "4px",
                                borderRadius: "8px",
                            }}>
                            {item}
                        </MenuItem>
                    ))}
                </Select>
                <Typography className="errorStyle">
                    {this.state.createUserClicked && this.state.selectedCompetencies.length === 0 && configJSON.errorText}
                </Typography>
            </Wrapper>
        )
    }

    userJobDescription = () => {
        return (
            <Wrapper>
                <Typography className="userInputLabel">
                    Job Descriptions <span>*</span>
                </Typography>
                <textarea
                    data-test-id="user_job_description"
                    className="jobDescriptionField"
                    placeholder="Write Job Descriptions..."
                    value={this.state.jobDescription}
                    onChange={this.handleJobDescriptionChange}
                />
                <Box className="errorStylejobDesc">
                    <Typography className="errorStyle">
                        {this.state.createUserClicked && !this.state.jobDescription && configJSON.errorText}
                    </Typography>
                    <Typography className="jobDescLimit">{this.state.jobDescription.length}/1000</Typography>
                </Box>
            </Wrapper>
        )
    }

    cancelButton = () => {
        return (
            <Wrapper>
                <Button className="cancelBtn" data-test-id="cancel_details" onClick={this.backToDashBoard}>
                    <Typography className="cancelText">
                        Cancel
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    createUserButton = () => {
        return (
            <Wrapper>
                <Button className="createuserBtn" data-test-id="create_user" onClick={this.handleCreateUser}>
                    <Typography className="createUserText">
                        {this.state.isEdit ? "Update User" : "Create User"}
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    createUserForm = () => {
        return (
            <Wrapper>
                <Box className="userItemMainBox">
                    <Card className="userCardDescBox">
                        <CardContent className="cardContentPadding">
                            <Grid container spacing={3.5} className="contentPadding">

                                <Grid item xs={12} md={3}>
                                    <Box
                                        className="profileImage"
                                        sx={{ backgroundColor: this.state.referenceImage ? "none" : "#111" }}>
                                        <Button
                                            component="label"
                                            data-test-id="upload_photo"
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: "transparent",
                                                    boxShadow: "none",
                                                }
                                            }} >
                                            <Box className="profileImageContainer">
                                                <img
                                                    className={this.state.referenceImage ? "userPhoto" : ""}
                                                    alt="Profile Image"
                                                    src={this.state.referenceImage || camera.default}
                                                />
                                                <Box className="editUserPhotoText editPhotoTextPosition">
                                                    {!this.state.referenceImage && "Edit photo"}
                                                </Box>
                                            </Box>
                                            <input
                                                data-test-id="upload_userImage"
                                                type="file"
                                                name="image"
                                                accept="image/png, image/jpeg"
                                                onChange={(event) => {
                                                    this.handleImageChange(event);
                                                }}
                                            />
                                        </Button>
                                    </Box>
                                    <Typography>
                                        {this.state.referenceImageError &&
                                            <Box className="profilePhotoError">* {this.state.referenceImageError}</Box>
                                        }
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={9} sx={{ marginTop: "20px" }}>
                                    <Grid container spacing={2.5}>
                                        <Grid item xs={12} md={6}>
                                            {this.userFirstName()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.userLastName()}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            {this.userEmail()}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            {this.userContactNumber()}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            {this.userStaffId()}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            {this.userBirthDate()}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            {this.userGender()}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            {this.userDesignation()}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            {this.userDepartment()}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            {this.userCompany()}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            {this.userType()}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider sx={{ margin: "20px 0px" }} />
                                </Grid>

                                <Grid item xs={12}>
                                    {this.userCompetencies()}
                                </Grid>

                                <Grid item xs={12}>
                                    {this.userJobDescription()}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {this.cancelButton()}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {this.createUserButton()}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            </Wrapper >
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="All users" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={11}>
                            <Grid container spacing={1} alignItems={"center"}>
                                <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}>
                                    <img src={backArrow.default}
                                        alt="Back Arrow"
                                        className="mainArrowrrowImg"
                                        data-test-id="back_arrow"
                                        onClick={this.backToDashBoard}
                                    />
                                </Grid>
                                <Grid item xs={10.5} sm={11} md={11.2} lg={11.5}>
                                    <Typography className="createUserTitle">
                                        {this.state.isEdit ? "Update User" : "Create New User"}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} className="createUserHeaderGrid">
                                <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                                    {this.createUserForm()}
                                    {this.successUserCreationDialog()}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    width: "100%",
    "& .userInputLabel": {
        fontSize: "14px",
        fontWeight: 700,
        fontFamily: "Urbanist",
        linHheight: "22px",
        color: "#344054",
        marginBottom: "2px"
    },
    "& .countryCodeText": {
        paddingTop: "3px",
        textAlign: "center",
        paddingRight: "12px"
    },
    "& .viewDetailButton": {
        fontWeight: 700,
        width: "30%",
        height: "56px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        padding: "16px 24px",
        gap: "8px",
        borderRadius: "8px",
        opacity: "0px",
        marginLeft: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .viewDetailButtonsBox": {
        margin: "16px 0px",
        paddingRight: "16px",
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .viewDetailButtonsBoxArabic": {
        display: "flex",
        justifyContent: "flex-end",
        paddingLeft: "16px",
        margin: "16px 0px"
    },
    "& .jobDescLimit": {
        display: "flex",
        fontSize: "12px",
        fontWeight: 400,
        justifyContent: "flex-end",
        fontFamily: "Urbanist",
    },
    "& .successUserDialogClose": {
        position: 'absolute',
        top: 16,
        color: "#334155"
    },
    "& .successUserImageBox": {
        display: "flex",
        padding: "10px 0px",
        justifyContent: "center"
    },
    "& .errorStylejobDesc": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .successUserDialogTitle": {
        color: "#1E1E1E",
        fontSize: "24px",
        lineHeight: "32px",
        fontFamily: "Urbanist",
        fontWeight: 700,
        margin: "8px 16px"
    },
    "& .profilePhotoError": {
        color: "#F30202",
        fontSize: "12px",
        fontStyle: "italic",
        fontWeight: "400",
        lineheight: "24px",
    },
    "& .viewDetailButtonArabic": {
        height: "56px",
        gap: "8px",
        borderRadius: "8px",
        opacity: "0px",
        fontSize: "16px",
        fontWeight: 700,
        width: "120px",
        fontFamily: "Urbanist",
        padding: "16px 39.5px 16px 36.5px",
        marginRight: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .jobDescError": {
        fontSize: "12px",
        fontWeight: 400,
        display: "flex",
        justifyContent: "flex-start",
        fontFamily: "Urbanist",
    },
    "& .errorStyle": {
        fontSize: "0.85rem",
        color: "#F30202",
        marginBottom: "0.5rem",
    },
    "& .permissionSubText": {
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#0F172A"

    },
    "& .permissionsCheckbox": {
        gap: '0px',
        borderRadius: '6px 0px 0px 0px',
        width: '20px',
        height: '52px',
        opacity: '1',
        color: '#D0D5DD',
        padding: '0',
    },
    "& .editPhotoText": {
        fontFamily: "Inter !important",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "22px",
        marginTop: "6px",
        textAlign: "center"
    },
    "& .dropDownplaceHolder": {
        fontSize: 16,
        color: "#DCDCDC",
        fontWeight: 400
    } as React.CSSProperties,
    "& .genderDropDown": {
        fontSie: '16px',
        fontWeight: 400,
        fontFamily: 'Urbanist',
        border: '1px solid lightgray',
        lineHight: "24px",
        textAlign: "left",
        borderRadius: '8px',
        height: '50px',
        padding: '12px 16px',
        width: '100%'
    },
    "& .cancelText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#000000",
        textTransform: "none"
    },
    "& .cancelBtn": {
        height: "58px",
        padding: "10px 16px 10px 16px",
        borderRadius: "4px",
        border: "1px solid #94A3B8",
        width: "100%",
        "&:hover": {
            background: "none"
        }
    },
    "& .createUserText": {
        color: "#FFFFFF",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textTransform: "none"
    },
    "& .createuserBtn": {
        height: "58px",
        padding: "10px 16px 10px 16px",
        borderRadius: "4px",
        border: "1px solid #94A3B8",
        background: "#044352",
        width: "100%"
    },
    "& .MuiSelect-icon": {
        transform: 'none !important',
    },
    "& .jobDescriptionField": {
        borderRadius: "8px",
        border: '1px solid #CBD5E1',
        fontWeight: 400,
        boxShadow: "0px 1px 2px 0px #1018280D",
        fontSie: "16px",
        fontFamily: 'Urbanist',
        lineHight: "24px",
        height: "98px",
        padding: "12px 16px",
        width: '100%',
        color: "rgba(0, 0, 0, 0.87)",
        "&:focus": {
            borderColor: "#CBD5E1",
            outline: "none",
        }
    },
    "& .userEnterInputField": {
        fontSie: '16px',
        fontWeight: 400,
        fontFamily: 'Urbanist',
        border: '1px solid lightgray',
        lineHight: "24px",
        textAlign: "left",
        borderRadius: '8px',
        height: '50px',
        padding: '12px 16px',
        width: '100%'
    },
    "& .birthDateField": {
        border: '1px solid lightgray',
        background: "white",
        borderRadius: '8px',
        height: '50px',
        width: '100%',
    },
    "& .userCardDescBox": {
        width: "55vw",
        margin: "4vh 3vw",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        "@media(max-width:1200px)": {
            width: "70vw",
        }
    },
    "& .userItemMainBox": {
        display: "flex",
        width: "100%",
        overflow: "hidden",
        justifyContent: "flex-start",
        "@media(max-width:900px)": {
            justifyContent: "center",
        }
    },
    "& .editUserPhotoText": {
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Inter !important",
        fontSize: "12px",
        marginTop: "6px",
        textAlign: "center"
    },
    "& .editPhotoTextPosition": {
        position: "relative",
        textTransform: "none",
        color: "#FFFFFF"
    },
    "& .userCardItem": {
        scrollSnapAlign: "center",
        flex: "0 0 auto",
    },
    "& .userDetailPadding": {
        padding: "30px 20px 30px 35px"
    },
    "& .cardContentPadding": {
        padding: "0px 0px 42px 0px"
    },
    "& .contentPadding": {
        padding: "40px"
    },
    "& .userPhoto": {
        height: "10vw",
        width: "10vw",
        borderRadius: "50%",
        maxWidth: "150px",
        maxHeight: "150px",
        minWidth: "80px",
        minHeight: "80px"
    },
    "& .profileImageContainer": {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    "& .profileImage": {
        borderRadius: "50%",
        height: "10vw",
        width: "10vw",
        maxWidth: "150px",
        maxHeight: "150px",
        minWidth: "80px",
        minHeight: "80px",
        opacity: "0px",
        zIndex: 10,
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "22px",
        border: "4px solid white",
    },
    "& .userCardContainer": {
        width: "100%",
        padding: "0px 8px",
        justifyContent: "center",
        "@media(max-width:900px)": {
            justifyContent: "flex-start",
        }
    },
    "& input::placeholder, textarea::placeholder": {
        color: "#cccccc"
    }
})
export const MainWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "42px",
    paddingBottom: "30px",
    "& .mainArrowrrowImg": {
        paddingRight: "12px"
    },
    "& .createUserTitle": {
        lineHeight: "30px",
        color: "#000104",
        fontFamily: "Urbanist",
        fontSize: "25px",
        fontWeight: 600,
        marginLeft: "12px"
    },
    "& .createUserHeaderGrid": {
        marginTop: "20px"
    },
})
// Customizable Area End
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.Welcometext = "Welcome to HR tech! Your account"
exports.welcontext1 = "is currently under review. �"
exports.signUpUrl = "/account_block/accounts";
exports.policyConsent ="I have read and agree to Privacy Policy";
exports.termsAndConditionEndPoint ="/bx_block_terms_and_conditions/term_and_condition"; 
exports.privacypolicyEndPoint ="/bx_block_terms_and_conditions/privacy_policy";
exports.termsAndConditionsMsg ="You must agree to the Terms and Conditions, Privacy policy to register";
exports.termsErrMsg ="You must agree to the Terms and Conditions";
exports.policyErrMsg = "You must agree to the Privacy policy";
exports.reviewUser="Thank you for signing up with your organization email. Our administrators are reviewing your request to ensure that you have the right access to the resources you need.";
exports.shortMessage ="Fusce volutpat lectus et nisl consectetur finibus. In vitae scelerisque augue, in varius eros.";
// Customizable Area End

import React from "react";

import {
    Box,
    // Customizable Area Start
    Grid,
    styled,
    Typography,
    // Customizable Area End
} from "@mui/material";

// Customizable Area Start
// Customizable Area End

import CandidateDashboardController, {
    Props,
} from "./CandidateDashboardController";

export default class CandidateDashboard extends CandidateDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

// Customizable Area Start
// Customizable Area End

render() {
    return (
        // Customizable Area Start
        <MainWrapper>
            <Grid container>
                <Grid item xs={12}>
                    <Typography></Typography>
                </Grid>
            </Grid>
        </MainWrapper>
        // Customizable Area End
    );
}
}

// Customizable Area Start
export const MainWrapper = styled(Box)({
    width: "100vw",
})
// Customizable Area End
import React from "react";

import {
    Box,
    // Customizable Area Start
    Grid,
    styled,
    Typography,
    Button,
    Card,
    CardContent,
    Divider,
    ThemeProvider,
    CssBaseline,
    createTheme,
    Dialog,
    DialogTitle,
    DialogContent,
    Checkbox,
    DialogActions,
    CircularProgress
    // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import { backgroundLayer, rightArrow, titleImage, headerLogo, linkView, view } from "./assets";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LandingPageController, {
    Props,
} from "./LandingPageController";
import 'swiper/swiper-bundle.css';
import { ContactUsWeb as Contactus } from "../../contactus/src/Contactus.web";

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: 'inherit',
                    '&:hover': {
                        backgroundColor: 'inherit',
                        boxShadow: 'none',
                    },
                },
            },
        },
    },
});

// Customizable Area End

export default class LandingPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    mainHeader = () => {
        const { headerFooterData } = this.state;
        const headerData = headerFooterData?.header;
        return (
            <Wrapper>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "logoBox" : "arabicLogoBox"}`}>
                            <img src={headerLogo.default} alt="Logo" />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Box className="hearderMainBox">
                            <Grid container alignItems="center" height="100%">
                                <Grid item xs={4} sm={3} md={4}>
                                    <Typography className="headerTypo" data-test-id="featuresRef" onClick={() => this.scrollToSection(this.featuresRef)}>{headerData?.header_features}</Typography>
                                </Grid>
                                <Grid item xs={4} sm={3} md={4}>
                                    <Typography className="headerTypo" data-test-id="testimonialsRef" onClick={() => this.scrollToSection(this.testimonialsRef)}>{headerData?.header_testimonials}</Typography>
                                </Grid>
                                <Grid item xs={4} sm={3} md={4}>
                                    <Typography className="headerTypo" data-test-id="contactUsRef" onClick={() => this.scrollToSection(this.contactUsRef)}>{headerData?.header_contact_us}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4} className="headerButtonBox">
                        {!this.state.headerFooterLoader && (
                            <Box className="languageTextWrapper">
                                <Button
                                    className="languageText"
                                    data-test-id="langDropdown"
                                    onClick={this.toggleDropdown}
                                >
                                    {this.state.selectedLanguage === "English" ? "عربي" : "English"}
                                    <img src={rightArrow.default} alt="Image" className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "englishArrow" : "arabicArrow"}`} />
                                </Button>
                                {this.state.languageDropdown && (
                                    <Box className="dropdownMenu">
                                        <Typography data-test-id="englishLang" className="dropdownItem" onClick={() => this.languageChange("English")}>English</Typography>
                                        <Typography data-test-id="arabicLang" className="dropdownItem" onClick={() => this.languageChange("عربي")}>عربي</Typography>
                                    </Box>
                                )}
                                <Button onClick={this.goToSignIn} className="signInText">{headerFooterData?.header?.sign_in_text}</Button>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Wrapper>
        )
    }
    getStarted = () => {
        const { getStartedData } = this.state;

        const backgroundImage = getStartedData?.background_image_url
            ? `url("${getStartedData?.background_image_url}")`
            : "none";

        return (
            <Wrapper>
                <Box className="imageGrid" ref={this.getStartedRef} sx={{ backgroundImage }}>
                    <Box className="imageGridLayer"></Box>
                </Box>

                <Box className="imageGridContent">
                    <Box className="imageGridBox1">
                        <Box className="getStartedImages">
                            <img src={getStartedData.image_urls?.[0]} alt="Image" className="imageGridBox1Avatar" />
                        </Box>
                        <img src={view.default} alt="Image" className="imageGridBox1Arrow" />
                    </Box>
                    <Box className="imageGridBox2">
                        <Box className="getStartedImages">
                            <img src={getStartedData.image_urls?.[1]} alt="Image" className="imageGridBox2Avatar" />
                        </Box>
                        <img src={view.default} className="imageGridBox2Arrow" />
                    </Box>
                    <Box className="imageGridBox3">
                        <Box className="getStartedImages">
                            <img src={getStartedData.image_urls?.[2]} className="imageGridBox3Avatar" />
                        </Box>
                        <img src={view.default} alt="Image" className="imageGridBox3Arrow" />
                    </Box>
                    <Box className="imageGridBox4">
                        <Box className="getStartedImages">
                            <img src={getStartedData.image_urls?.[3]} alt="Image" className="imageGridBox4Avatar" />
                        </Box>
                        <img src={view.default} alt="Image" className="imageGridBox4Arrow" />
                    </Box>
                    <Box className="createFastMainBox">
                        {!this.state.getStartedLoader && (<>
                            <Box className="createFastMargin">
                                <Button className="createFastButton" onClick={() => this.scrollToSection(this.contactUsRef)}>
                                    <img src={titleImage.default} alt="Image" />
                                    <Typography
                                        className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "createFastText" : "arabicCreateFastText"}`} >
                                        {getStartedData.fast_button}
                                    </Typography>
                                </Button>
                            </Box>
                            <Box>
                                <Typography className="imageGridTitle2">{getStartedData.start_title}</Typography>
                            </Box>
                        </>)}
                        {this.state.getStartedLoader && (
                            <Box className="loader-outer">
                                <Box className="loader-inner">
                                    <CircularProgress />
                                </Box>
                            </Box>
                        )}
                        <Box>
                            <Typography className="createFastDesc">{getStartedData.start_tag_line}</Typography>
                        </Box>
                        {!this.state.getStartedLoader && (
                            <Box className="getStartedBox">
                                <Button onClick={this.goToSignup} className="getStartedButton">
                                    <Typography className="getStartedText">{getStartedData.get_started}</Typography>
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>

                <Box className="imageGridLayer"></Box>
            </Wrapper>
        )

    }
    jdLinks = () => {
        const links = this.state.getStartedData.border_text;
        return (
            <Wrapper>
                <Grid container className="linksGrid" alignItems="center">
                    <Grid item className="linkItem">
                        <div className="get-started-swiper-container">
                            <div className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "get-started-swiper-wrapper" : "get-started-swiper-wrapper-arabic"}`}>
                                {links && links.map((links, key) => {
                                    return (
                                        <div className="get-started-swiper-slide" key={key}>
                                            <Box className="jdLinksBox">
                                                <Typography className="linkImage"> <img src={linkView.default} alt="Image" /></Typography>
                                                <Typography className="linkText">{links}</Typography>
                                            </Box>
                                        </div>
                                    )
                                })}
                                {links && links.map((links, key) => {
                                    return (
                                        <div className="get-started-swiper-slide" key={key}>
                                            <Box className="jdLinksBox">
                                                <Typography className="linkImage"> <img src={linkView.default} alt="Image" /></Typography>
                                                <Typography className="linkText">{links}</Typography>
                                            </Box>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Wrapper>
        )
    }

    learnMoreDialog = () => {
        const { getFeaturesData, selectedFeatureIndex } = this.state;
        const feature = getFeaturesData.features && getFeaturesData.features[selectedFeatureIndex];
        return (
            <Dialog
                open={this.state.openLearnMore}
                onClose={this.handleCloseLearnMore}
                PaperProps={{
                    sx: {
                        direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
                        overflow: 'hidden',
                        borderRadius: "8px 8px 32px 8px"
                    }
                }}
            >
                <DialogTitle>
                    <Wrapper>
                        <Typography className="learnDialogTitle">
                            {feature?.feature_sub_title}
                        </Typography>
                        <IconButton
                            data-test-id="closeLernMore"
                            aria-label="close"
                            onClick={this.handleCloseLearnMore}
                            className="learnDialogClose"
                            sx={{
                                right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                                left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Wrapper>
                </DialogTitle>
                <Divider sx={{ color: "#E2E8F0" }} />
                <DialogContent
                    sx={{
                        maxHeight: '50vh',
                        overflowY: 'auto'
                    }}
                >
                    <Wrapper>
                        <Box className="learnMoreImageBox">
                            <Box className="featureImgBox featureImageStyle1">
                                <img src={feature?.feature_icon_url} alt="Image" className="featureGroupImg" height="82px" width="82px" />
                            </Box>
                        </Box>
                    </Wrapper>
                    <Typography
                        sx={{
                            fontFamily: "Urbanist",
                            padding: "20px 16px",
                            fontSize: "18px",
                            fontWeight: 400,
                            lineHeight: "26px",
                            color: "#0F172A"
                        }}>
                        {feature?.feature_description.split('\r\n').map((text, index) => (
                            <React.Fragment key={index}>
                                {text}
                                <br />
                            </React.Fragment>
                        ))}
                    </Typography>
                </DialogContent>
            </Dialog>
        )
    }

    ourFeatures = () => {
        const { getFeaturesData } = this.state;
        return (
            <Wrapper>
                <Grid container alignItems="center" justifyContent="center" className="ourFeatureContainer">
                    <Grid item xs={12}>
                        {!this.state.ourFeaturesLoader && (
                            <Box className="featureDescMainBox" ref={this.featuresRef}>
                                <Button className="ourFeatureButton">{getFeaturesData.our_feature}</Button>
                            </Box>
                        )}
                    </Grid>

                    <Grid item className="featureTitleTextMainGrid">
                        <Typography className="featureTitleText">
                            {getFeaturesData.feature_title}</Typography>
                    </Grid>

                    <Grid item xs={10} className="descriptionTop">
                        <Grid container spacing={2}>
                            {getFeaturesData.features && getFeaturesData.features.map((item, currentIndex) => (
                                <>
                                    <Grid item xs={12} md={4}>
                                        <Box className="featureDescMainBox">
                                            <Box className="featureImgBox">
                                                <img src={item.feature_icon_url} alt="Image" className="featureGroupImg" height="88px" width="88px" />
                                            </Box>
                                            {this.state.ourFeaturesLoader && (
                                                <Box className="loader-outer">
                                                    <Box className="loader-inner">
                                                        <CircularProgress />
                                                    </Box>
                                                </Box>
                                            )}
                                            <Box className="featureTitleDescMainBox">
                                                <Typography className="featureDescTitle">{item.feature_sub_title}</Typography>
                                                <Typography className="featureDesc">{item.feature_description.length > 50 ? `${item.feature_description.substring(0, 50)}...` : item.feature_description}</Typography>
                                                <Typography
                                                    data-test-id="learnMoreLang"
                                                    onClick={() => this.handleOpenLearnMore(currentIndex)}
                                                    className="learnMore">{this.state.selectedLanguage === "English" ? "Learn more" : "يتعلم أكثر"}
                                                    <img src={rightArrow.default} alt="Image" className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "englishArrow" : "arabicArrow"}`} />
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {this.learnMoreDialog()}
                                    </Grid >
                                </>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Wrapper>
        )
    }

    testimonials = () => {
        const { getTesimonialsData } = this.state;
        return (
            <Wrapper>
                <Grid container className="testimonialsContainer" spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        {!this.state.testimonialsLoader && (
                            <Box className="featureDescMainBox" ref={this.testimonialsRef}>
                                <Button className="ourFeatureButton">{getTesimonialsData.testimonial}</Button>
                            </Box>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className="featureTitleText">{getTesimonialsData.testimonial_title}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Box className="testimonialsItemMainBox">
                            <Box className="cardContainer">
                                <div className="swiper" ref={this.swiperContainerRef}>
                                    <div className="swiper-wrapper">
                                        {getTesimonialsData.testimonials && getTesimonialsData.testimonials.map((item, index) => {
                                            return (
                                                <div className="swiper-slide" key={index}
                                                    onMouseEnter={this.handleMouseEnter}
                                                    onMouseLeave={this.handleMouseLeave}>
                                                    <Box className="cardItem">
                                                        <Card>
                                                            <CardContent className="cardDescBox">
                                                                <Box>
                                                                    <Box>
                                                                        <Typography className="testimonialCardDesc">
                                                                            {item.testimonial_desc}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box className="cardUserMainBox">
                                                                        <Box className="userImageBox">
                                                                            <img src={item.profile_photo_url} className="userImageBox userImage" />
                                                                        </Box>
                                                                        <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "userNameIdBox" : "userNameIdBoxArabic"}`}>
                                                                            <Typography className="userIdName">{item.testimonial_full_name}</Typography>
                                                                            <Typography className="userIdText">{item.testimonial_user_name}</Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </CardContent>
                                                        </Card>
                                                    </Box>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </Box>

                            <div ref={this.nextButtonRef} className="swiper-button-next"></div>
                            <div ref={this.prevButtonRef} className="swiper-button-prev"></div>
                            <div ref={this.paginationRef} className="swiper-pagination"></div>
                        </Box>
                    </Grid>
                </Grid>
            </Wrapper>
        )
    }
    footer = () => {
        const { headerFooterData } = this.state;
        const footerData = headerFooterData?.footer;
        const headerData = headerFooterData?.header;

        return (
            <Wrapper>
                <Grid container alignItems="center" justifyContent="center" className="footerContainer">
                    <Grid item xs={10} className="footerMainItemGrid">
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <Typography className="footerTypo">{footerData?.footer_headline}</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {!this.state.headerFooterLoader && (
                                    <Box className="footerSignInBox">
                                        <Button onClick={this.goToSignIn} className="footerSignInText">{headerData?.sign_in_text}</Button>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={10} >
                        <Box>
                            <Divider />
                        </Box>
                    </Grid>

                    <Grid item xs={10}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Box className="footerAboutUsBox">
                                    <Box>
                                        <Typography className="footerAboutus">{footerData?.footer_title}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography className="footerAboutUsDesc">
                                            {footerData?.footer_description}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container justifyContent="flex-end">
                                    <Grid item xs={6} md={4} className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "" : "footerCompanyGrid"}`}>
                                        <Box className="footerAboutUsBox">
                                            <Box>
                                                <Typography className="footerAboutus">{footerData?.footer_company_info}</Typography>
                                            </Box>
                                            <Box>
                                                <Typography className="footerCompanyLinks" data-test-id="getStartedRef" onClick={() => this.scrollToSection(this.getStartedRef)}>{footerData?.footer_about_us}</Typography>
                                                <Typography className="footerCompanyLinks" data-test-id="footerFeatures" onClick={() => this.scrollToSection(this.featuresRef)}>{footerData?.footer_features}</Typography>
                                                <Typography className="footerCompanyLinks" data-test-id="footerTerms" onClick={() => this.handleClickOpen()}>{footerData?.footer_terms_conditions}</Typography>
                                                {this.renderTermsAndCondition()}
                                                <Typography className="footerCompanyLinks" data-test-id="footerPP" onClick={() => this.handleClickOpenPrivacy()}>{footerData?.footer_privacy_policy}</Typography>
                                                {this.renderPrivacyPolicy()}
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <Box className="footerImageBox">
                                            <Box><img src={footerData?.twitter_icon_url} alt="Image" data-test-id="twitterLink" onClick={this.navigateToTwitter} height="13px" width="16px" /></Box>
                                            <Box><img src={footerData?.facebook_icon_url} alt="Image" data-test-id="facebookLink" onClick={this.navigateToFaceBook} height="17px" width="10px" /></Box>
                                            <Box><img src={footerData?.instagram_icon_url} alt="Image" data-test-id="instaLink" onClick={this.navigateToInsta} height="19px" width="19px" /></Box>
                                            <Box><img src={footerData?.linkedin_icon_url} alt="Image" data-test-id="linkedinLink" onClick={this.navigateToLinkedIn} height="24px" width="24px" /></Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Wrapper >
        )
    }

    renderTermsAndCondition = () => {
        return (
            <Dialog
                open={this.state.openTerms}
                onClose={this.handleClose}
                PaperProps={{
                    sx: {
                        direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
                        overflow: 'hidden',
                        borderRadius: "8px 8px 32px 8px",
                        maxHeight: '80vh'
                    }
                }}
            >
                <DialogTitle>
                    <Wrapper>
                        <Typography sx={{
                            fontFamily: "Urbanist",
                            fontWeight: 700,
                            fontSize: "24px",
                            lineHeight: "32px",
                            margin: "8px 16px",
                            color: "#000000"
                        }}>{this.state.termsAndConditionsData.title}</Typography>
                        <IconButton
                            aria-label="close"
                            onClick={this.handleClose}
                            className="learnDialogClose"
                            sx={{
                                right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                                left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Wrapper>
                </DialogTitle>
                <Divider sx={{ color: "#E2E8F0" }} />
                <DialogContent
                    sx={{
                        maxHeight: '36vh',
                        overflowY: 'auto'
                    }}
                >
                    <Wrapper>
                        <Typography className="termsDesc">
                            {this.state.termsAndConditionsData.description?.split('\r\n').map((text, index) => (
                                <React.Fragment key={index}>
                                    {text}
                                    <br />
                                </React.Fragment>
                            ))}
                        </Typography>
                        {!this.state.termsLoader && (
                            <Box className="termsCheckbox">
                                <Checkbox
                                    checked={this.state.checkedTerms}
                                    onChange={this.handleCheckboxChange}
                                    color="primary"
                                    data-test-id="termsCheckbox"
                                />
                                <span>{this.state.termsAndConditionsData.checkbox}</span>
                            </Box>
                        )}
                    </Wrapper>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Wrapper>
                        {!this.state.termsLoader && (
                            <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "termsActionButtonsBox" : "termsActionButtonsBoxArabic"}`}>
                                <Button
                                    onClick={this.handleClose}
                                    variant="outlined"
                                    data-test-id="termsCancel"
                                    className="termsCancelButton"
                                >
                                    {this.state.selectedLanguage === "English" ? "Cancel" : "يلغي"}
                                </Button>
                                <Button
                                    onClick={this.handleClose}
                                    variant="contained"
                                    color="primary"
                                    className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "termsAgreeButton" : "termsAgreeButtonArabic"}`}
                                >
                                    {this.state.selectedLanguage === "English" ? "Agree" : "يوافق"}
                                </Button>
                            </Box>
                        )}
                    </Wrapper>
                </DialogActions>
            </Dialog>
        )
    }

    renderPrivacyPolicy = () => {
        return (
            <Wrapper>
                <Dialog
                    open={this.state.openPrivacy}
                    onClose={this.handleClosePrivacy}
                    PaperProps={{
                        sx: {
                            direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
                            overflow: 'hidden',
                            borderRadius: "8px 8px 32px 8px"
                        }
                    }}
                >
                    <DialogTitle>
                        <Wrapper>
                            <Typography sx={{
                                fontFamily: "Urbanist",
                                fontSize: "24px",
                                fontWeight: 700,
                                lineHeight: "32px",
                                margin: "8px 16px"
                            }}>{this.state.privacyPolicyData.title}</Typography>
                            {!this.state.privacyLoader && (
                                <IconButton
                                    aria-label="close"
                                    onClick={this.handleClosePrivacy}
                                    className="learnDialogClose"
                                    sx={{
                                        right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                                        left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </Wrapper>
                    </DialogTitle>
                    <Divider sx={{ color: "#E2E8F0" }} />
                    <DialogContent
                        sx={{
                            maxHeight: '36vh',
                            overflowY: 'auto'
                        }}
                    >
                        <Wrapper>
                            <Typography className="termsDesc">
                                {this.state.privacyPolicyData.description?.split('\r\n').map((text, index) => (
                                    <React.Fragment key={index}>
                                        {text}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </Typography>
                            {!this.state.privacyLoader && (
                                <Box className="termsCheckbox">
                                    <Checkbox
                                        checked={this.state.checkedPrivacy}
                                        onChange={this.handleCheckboxChangePrivacy}
                                        color="primary"
                                        data-test-id="privacyCheckbox"
                                    />
                                    <span>{this.state.privacyPolicyData.checkbox}</span>
                                </Box>
                            )}
                        </Wrapper>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Wrapper>
                            {!this.state.privacyLoader && (
                                <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "termsActionButtonsBox" : "termsActionButtonsBoxArabic"}`}>
                                    <Button
                                        onClick={this.handleClosePrivacy}
                                        variant="outlined"
                                        data-test-id="privacyCancel"
                                        className="termsCancelButton"
                                    >
                                        {this.state.selectedLanguage === "English" ? "Cancel" : "يلغي"}
                                    </Button>
                                    <Button
                                        onClick={this.handleClosePrivacy}
                                        variant="contained"
                                        color="primary"
                                        className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "termsAgreeButton" : "termsAgreeButtonArabic"}`}
                                    >
                                        {this.state.selectedLanguage === "English" ? "Agree" : "يوافق"}
                                    </Button>
                                </Box>
                            )}
                        </Wrapper>
                    </DialogActions>
                </Dialog>
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <MainWrapper ref={this.mainHeaderRef}>
                    <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "" : "arabicDirection"}`}>
                        <CssBaseline />
                        <Grid container>
                            <Grid item xs={12}>
                                {this.mainHeader()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.getStarted()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.jdLinks()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.ourFeatures()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.testimonials()}
                            </Grid>
                            <Grid item xs={12}>
                                <div ref={this.contactUsRef}>
                                    <Contactus handleClickOpenPrivacy={this.handleClickOpenPrivacy} mainHeaderRef={this.mainHeaderRef} scrollToSection={this.scrollToSection} navigation={this.props.navigation} id={this.props.id} />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                {this.footer()}
                            </Grid>
                        </Grid>
                    </Box>
                </MainWrapper>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    width: "100%",
    "& .get-started-swiper-container": {
        width: "100%",
        overflow: "hidden",
        position: "relative",
        whiteSpace: "nowrap",
    },
    "& .get-started-swiper-slide": {
        display: 'inline-block',
        padding: "0px 20px",
        width: "auto"
    },
    "& .get-started-swiper-wrapper": {
        display: "flex",
        width: "calc(100% * 2)",
        animation: "marquee-ltr 120s linear infinite",

        '@keyframes marquee-ltr': {
            '0%': {
                transform: 'translateX(0%)',
            },
            '100%': {
                transform: 'translateX(-133.33%)',
            },
        },

        '@keyframes marquee-rtl': {
            '0%': {
                transform: 'translateX(0%)',
            },
            '100%': {
                transform: 'translateX(133.33%)',
            },
        },
    },
    "& .get-started-swiper-wrapper-arabic": {
        display: "flex",
        width: "calc(100% * 2)",
        animation: "marquee-rtl 120s linear infinite",

        '@keyframes marquee-ltr': {
            '0%': {
                transform: 'translateX(0%)',
            },
            '100%': {
                transform: 'translateX(-133.33%)',
            },
        },

        '@keyframes marquee-rtl': {
            '0%': {
                transform: 'translateX(0%)',
            },
            '100%': {
                transform: 'translateX(133.33%)',
            },
        },
    },
    "& .mainHeading": {
        fontFamily: "Urbanist",
        lineHeight: "24px",
        fontSize: "16px",
        fontWeight: 700,
        height: "111px"
    },
    "& .logoBox": {
        marginLeft: "36%",
        height: "110px",
        width: "110px",
        display: "flex",
        justifyContent: "center",
        "@media(max-width:900px)": {
            margin: "0px 0px 8px 8px",
        }
    },
    "& .arabicLogoBox": {
        marginRight: "36%",
        height: "110px",
        width: "110px",
        display: "flex",
        justifyContent: "center",
        "@media(max-width:900px)": {
            margin: "0px 0px 8px 8px",
        }
    },
    "& .hearderMainBox": {
        margin: "0px 100px 8px 8px",
        "@media(max-width:1200px)": {
            margin: "0px 8px 8px 8px",
        }
    },
    "& .headerButtonBox": {
        marginTop: 0
    },
    "& .headerTypo": {
        fontFamily: "Urbanist",
        lineHeight: "24px",
        fontSize: "16px",
        fontWeight: 700,
        cursor: "pointer",
        "@media(max-width:960px)": {
            fontSize: "14px"
        }
    },
    "& .getStartedImages": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .imageGrid": {
        height: "100vh",
        width: "100vw",
        position: "absolute",
        zIndex: 1,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        "@media(max-width:960px)": {
            height: "80vh",
        },
        "@media(min-width:960px)": {
            minHeight: "750px",
        }
    },
    "& .imageGridLayer": {
        height: "100vh",
        width: "100vw",
        backgroundImage: `url(${backgroundLayer.default})`,
        opacity: "85%",
        zIndex: 1,
        "@media(max-width:960px)": {
            height: "80vh",
        },
        "@media(min-width:960px)": {
            minHeight: "750px",
        }
    },
    "& .imageGridContent": {
        height: "100vh",
        width: "100vw",
        zIndex: 2,
        position: "absolute",
        "@media(max-width:960px)": {
            height: "80vh",
        },
        "@media(min-width:960px)": {
            minHeight: "750px",
        }
    },
    "& .imageGridBox1": {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        left: "10%",
        top: "8%",
        width: "200px",
        height: "200px",
        "@media(max-width:960px)": {
            left: "10%",
            top: "8%",
            width: "160px",
            height: "160px",
        },
        "@media(max-width:600px)": {
            left: "10%",
            top: "8%",
            width: "120px",
            height: "120px",
        },
        "@media(max-width:360px)": {
            left: "10%",
            top: "10%",
            width: "100px",
            height: "100px",
        }
    },
    "& .imageGridBox1Avatar": {
        position: "absolute",
        width: "50%",
        height: "50%",
        borderRadius: "50%",
        border: "3px solid white",
        transform: "translate(-40px, -40px)",
        "@media(max-width:600px)": {
            transform: "translate(-30px, -30px)",
        },
        "@media(max-width:360px)": {
            transform: "translate(-30px, -30px)",
        }
    },
    "& .imageGridBox1Arrow": {
        width: "70%",
        right: 0,
        bottom: 0,
        transform: "translate(0px, 0px)",
        position: "absolute",
        "@media(max-width:960px)": {
            transform: "translate(0px, 0px)",
        },
        "@media(max-width:600px)": {
            transform: "translate(0px, 0px)",
        },
        "@media(max-width:360px)": {
            transform: "translate(-10px, -10px)",
        }
    },
    "& .englishArrow": {
        margin: "0px 0px 3px 8px"
    },
    "& .arabicArrow": {
        margin: "0px 8px 3px 0px",
        transform: "rotate(180deg)"
    },
    "& .imageGridBox2": {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        right: "10%",
        top: "8%",
        width: "200px",
        height: "200px",
        "@media(max-width:960px)": {
            right: "10%",
            top: "8%",
            width: "160px",
            height: "160px",
        },
        "@media(max-width:600px)": {
            right: "10%",
            top: "7.5%",
            width: "120px",
            height: "120px",
        },
        "@media(max-width:360px)": {
            right: "10%",
            top: "10%",
            width: "100px",
            height: "100px",
        }
    },
    "& .imageGridBox2Avatar": {
        position: "absolute",
        width: "50%",
        height: "50%",
        borderRadius: "50%",
        border: "3px solid white",
        transform: "translate(40px, -40px)",
        "@media(max-width:600px)": {
            transform: "translate(30px, -30px)",
        },
        "@media(max-width:360px)": {
            transform: "translate(30px, -30px)",
        }
    },
    "& .imageGridBox2Arrow": {
        width: "70%",
        left: "-5px",
        bottom: "2px",
        transform: "translate(0px, 0px)",
        position: "absolute",
        rotate: "90deg",
        "@media(max-width:960px)": {
            transform: "translate(0px, 0px)",
        },
        "@media(max-width:600px)": {
            transform: "translate(0px, 0px)",
        },
        "@media(max-width:360px)": {
            transform: "translate(-10px, -10px)",
        }
    },

    "& .imageGridBox3": {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        left: "15%",
        bottom: "8%",
        width: "200px",
        height: "200px",
        "@media(max-width:960px)": {
            left: "15%",
            bottom: "8%",
            width: "160px",
            height: "160px",
        },
        "@media(max-width:600px)": {
            left: "15%",
            bottom: "8%",
            width: "120px",
            height: "120px",
        },
        "@media(max-width:360px)": {
            left: "15%",
            bottom: "10%",
            width: "100px",
            height: "100px",
        }
    },
    "& .imageGridBox3Avatar": {
        position: "absolute",
        width: "50%",
        height: "50%",
        borderRadius: "50%",
        border: "3px solid white",
        transform: "translate(-40px, 40px)",
        "@media(max-width:600px)": {
            transform: "translate(-30px, 30px)",
        },
        "@media(max-width:360px)": {
            transform: "translate(-30px, 30px)",
        }
    },
    "& .imageGridBox3Arrow": {
        width: "70%",
        right: 0,
        top: 0,
        transform: "translate(0px, 0px)",
        position: "absolute",
        rotate: "-90deg",
        "@media(max-width:960px)": {
            transform: "translate(0px, 0px)",
        },
        "@media(max-width:600px)": {
            transform: "translate(0px, 0px)",
        },
        "@media(max-width:360px)": {
            transform: "translate(-10px, -10px)",
        }
    },

    "& .imageGridBox4": {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        right: "15%",
        bottom: "8%",
        width: "200px",
        height: "200px",
        "@media(max-width:960px)": {
            right: "15%",
            bottom: "8%",
            width: "160px",
            height: "160px",
        },
        "@media(max-width:600px)": {
            right: "15%",
            bottom: "8%",
            width: "120px",
            height: "120px",
        },
        "@media(max-width:360px)": {
            right: "15%",
            bottom: "10%",
            width: "100px",
            height: "100px",
        }
    },
    "& .imageGridBox4Avatar": {
        position: "absolute",
        width: "50%",
        height: "50%",
        borderRadius: "50%",
        border: "3px solid white",
        transform: "translate(40px, 40px)",
        "@media(max-width:600px)": {
            transform: "translate(30px, 30px)",
        },
        "@media(max-width:360px)": {
            transform: "translate(30px, 30px)",
        }
    },
    "& .imageGridBox4Arrow": {
        width: "70%",
        left: 0,
        top: "-5px",
        transform: "translate(0px, 0px)",
        position: "absolute",
        rotate: "180deg",
        "@media(max-width:960px)": {
            transform: "translate(0px, 0px)",
        },
        "@media(max-width:600px)": {
            transform: "translate(0px, 0px)",
        },
        "@media(max-width:360px)": {
            transform: "translate(-10px, -10px)",
        }
    },
    "& .imageGridTitle2": {
        fontFamily: "Urbanist",
        fontSize: "40px",
        width: "50vw",
        fontWeight: 700,
        lineHeight: "60px",
        textAlign: "center",
        color: "#F8FAFC",
        paddingTop: "12px",
        "@media(max-width:1182px)": {
            fontSize: "28px",
            lineHeight: "38px",
            width: "75vw",
            paddingTop: "16px",
        },
        "@media(max-width:960px)": {
            fontSize: "24px",
            lineHeight: "38px",
            width: "75vw",
            paddingTop: "14px",
        },
        "@media(max-width:600px)": {
            fontSize: "18px",
            lineHeight: "24px",
            width: "75vw",
            paddingTop: "12px",
        },
        "@media(max-width:360px)": {
            paddingTop: "10px",
            fontSize: "14px",
            lineHeight: "24px",
            width: "75vw"
        }
    },
    "& .createFastMainBox": {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        color: "white"
    },
    "& .loader-outer": {
        position: "absolute",
        left: 0,
        bottom: 0,
        height: "100%",
        minHeight: "100vh",
        top: 0,
        right: 0,
        zIndex: 1,
        backgroundColor: "rgb(0, 0, 0, .4)",
    },
    "& .loader-inner": {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        top: "40%",
        left: "50%",
    },
    "& .createFastButton": {
        padding: "20px",
        height: "48px",
        backgroundColor: "#FFFFFF",
        border: "1px solid #3333331A",
        borderRadius: "27px",
        pointer: "cursor",
        "&:hover": {
            backgroundColor: "#FFFFFF",
        },
    },
    "& .createFastText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "center",
        color: "#044352",
        marginLeft: "8px",
        "@media(max-width:960px)": {
            fontSize: "16px",
            lineHeight: "20px",
            marginLeft: "4px",
        },
        "@media(max-width:600px)": {
            fontSize: "12px",
            lineHeight: "20px",
            marginLeft: "4px",
        },
        "@media(max-width:360px)": {
            fontSize: "12px",
            lineHeight: "20px",
            marginLeft: "4px",
        }
    },
    "& .arabicCreateFastText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "center",
        color: "#044352",
        marginRight: "8px",
        "@media(max-width:960px)": {
            fontSize: "16px",
            lineHeight: "20px",
            marginLeft: "4px",
        },
        "@media(max-width:600px)": {
            fontSize: "12px",
            lineHeight: "20px",
            marginLeft: "4px",
        },
        "@media(max-width:360px)": {
            fontSize: "12px",
            lineHeight: "20px",
            marginLeft: "4px",
        }
    },
    "& .createFastDesc": {
        fontFamily: "Urbanist",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "32px",
        textAlign: "center",
        color: "#F1F5F9",
        padding: "12px",
        "@media(max-width:1182px)": {
            width: "90vw"
        },
        "@media(max-width:960px)": {
            fontSize: "20px",
            lineHeight: "36px",
            width: "90vw"
        },
        "@media(max-width:600px)": {
            fontSize: "16px",
            lineHeight: "24px",
            width: "80vw"
        },
        "@media(max-width:360px)": {
            paddingTop: "10px",
            fontSize: "14px",
            lineHeight: "24px",
            width: "80vw"
        }
    },
    "& .getStartedBox": {
        paddingTop: "36px",
        "@media(max-width:960px)": {
            paddingTop: "20px",
        },
        "@media(max-width:600px)": {
            paddingTop: "15px",
        },
        "@media(max-width:360px)": {
            paddingTop: "8px",
        }
    },
    "& .getStartedButton": {
        height: "60px",
        backgroundColor: "#CBD5E1",
        padding: "0px 30px",
        borderRadius: "0px",
        "&:hover": {
            backgroundColor: "#CBD5E1",
        },
        "@media(max-width:960px)": {
            padding: "0px 30px",
        },
        "@media(max-width:600px)": {
            padding: "0px 30px",
            height: "50px"
        },
        "@media(max-width:360px)": {
            padding: "0px 30px",
            height: "45px"
        }
    },
    "& .getStartedText": {
        fontFamily: "Urbanist",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "28px",
        textAlign: "left",
        color: "#044352",
        textTransform: "none",
        "@media(max-width:960px)": {
            fontSize: "22px",
            lineHeight: "38px",
        },
        "@media(max-width:600px)": {
            fontSize: "16px",
            lineHeight: "24px",
        },
        "@media(max-width:360px)": {
            fontSize: "14px",
            lineHeight: "24px",
        }
    },
    "& .linksGrid": {
        height: "120px",
        backgroundColor: "#044352",
        display: "flex",
        overflowX: "hidden",
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        "@media(max-width:960px)": {
            height: "90px",
        },
        "@media(max-width:600px)": {
            height: "80px",
        },
        "@media(max-width:360px)": {
            height: "70px",
        }
    },
    "& .linkItem": {
        minWidth: "100px",
        padding: "10px",
        color: "white",
        textAlign: "center",
        whiteSpace: "nowrap"
    },
    "& .jdLinksBox": {
        display: "flex",
        alignItems: "center"
    },
    "& .linkText": {
        color: "#FAF9F7",
        fontFamily: "Syne",
        display: "inline",
        fontSize: "16px",
        fontWeight: 600,
        marginLeft: "30px"
    },
    "& .languageTextWrapper": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%",
        gap: "8px",
        marginBottom: "16px",
        "@media(max-width:799px)": {
            flexWrap: "wrap"
        },
    },
    "& .dropdownMenu": {
        display: "flex",
        flexDirection: "column",
        background: "white",
        border: "1px solid #ccc",
        position: "absolute",
        marginTop: "8px",
        zIndex: 1000,
        maxWidth: "157px",
        width: "40%",
        top: "68px",
        marginLeft: 10,
        "@media(max-width:960px)": {
            width: "80%",
            top: "195px"
        },
    },
    "& .dropdownItem": {
        padding: "8px",
        cursor: "pointer"
    },
    "& .dropdownItem:hover": {
        backgroundColor: "#E2E8F0",
    },
    "& .createFastMargin": {
        marginTop: "-10%"
    },
    "& .languageText": {
        border: "2px solid #044352",
        fontSize: "20px",
        height: "24px",
        padding: "22px",
        fontWeight: 700,
        maxWidth: "157px",
        width: "40%",
        color: "#044352",
        margin: "0px 8px",
        textTransform: "none",
        "@media(max-width:960px)": {
            fontSize: "18px",
            width: "80%"
        },
    },
    "& .signInText": {
        margin: "0px 8px",
        fontSize: "20px",
        fontWeight: 700,
        maxWidth: "245px",
        width: "50%",
        height: "30px",
        padding: "24px",
        backgroundColor: "#044352",
        color: "#FFFFFF",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352",
        },
        "@media(max-width:960px)": {
            width: "80%",
        },
        "@media(max-width:600px)": {
            width: "80%",
        },
        "@media(max-width:360px)": {
            width: "40%",
        },
    },
    "& .linkImage": {
        display: "inline",
        marginLeft: "30px",
        marginBottom: "10px"
    },
    "& .ourFeatureContainer": {
        marginTop: "94px",
        "@media(max-width:960px)": {
            marginTop: "60px",
        }
    },
    "& .footerContainer": {
        backgroundColor: "#FAF9F7"
    },
    "& .footerImageBox": {
        marginTop: "56px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    "& .testimonialsContainer": {
        marginTop: "48px",
        padding: "50px 0px 70px 0px",
        backgroundColor: "#FAF9F7",
        "@media(max-width:960px)": {
            marginTop: "32px",
        }
    },
    "& .ourFeatureButton": {
        height: "48px",
        padding: "0px 40px",
        position: 'relative',
        border: "2px solid transparent",
        borderRadius: "50px",
        background: "linear-gradient(97.86deg, #e4f2f7 -48.42%, #f0f7fa 120%), linear-gradient(97.86deg, rgba(4, 67, 82, 0.5) -48.42%, rgba(4, 67, 82, 0) 100%)",
        backgroundOrigin: "border-box",
        backgroundClip: "padding-box, border-box",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#044352",
        fontWeight: "700",
        fontFamily: "Urbanist",
        fontSize: "16px",
        "&::before": {
            content: '""',
            position: 'absolute',
            top: "-2px",
            right: "-2px",
            bottom: "-2px",
            left: "-2px",
            borderRadius: "50px",
            background: "linear-gradient(97.86deg, rgba(4, 88, 82, 0.5) -100%, rgba(4, 67, 82, 0) 150%)",
            zIndex: -1,
        }
    },
    "& .featureTitleText": {
        fontFamily: "Urbanist",
        fontSize: "36px",
        fontWeight: "700",
        lineHeight: "44px",
        textAlign: "center",
        color: "#334155",
        "@media(max-width:1100px)": {
            fontSize: "32px",
        },
        "@media(max-width:960px)": {
            fontSize: "28px",
        },
        "@media(max-width:840px)": {
            fontSize: "24px",
        },
        "@media(max-width:720px)": {
            fontSize: "22px",
        },
        "@media(max-width:660px)": {
            fontSize: "20px",
            lineHeight: "36px"
        },
    },
    "& .featureTitleTextMainGrid": {
        margin: "16px",
        maxWidth: "45%",
        "@media(max-width:1390px)": {
            maxWidth: "73%"
        },
        "@media(max-width:1600px)": {
            maxWidth: "50%"
        },
        "@media(max-width:1700px)": {
            maxWidth: "50%"
        },
    },
    "& .featureDescMainBox": {
        display: "flex",
        justifyContent: "center"
    },
    "& .learnMoreImageBox": {
        display: "flex",
        justifyContent: "center",
        padding: "20px 0px"
    },
    "& .featureGroupImg": {
        borderRadius: "12px"
    },
    "& .featureImgBox": {
        minWidth: "88px",
        height: "88px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "@media(max-width:960px)": {
            minWidth: "66px",
            height: "66px",
        },
    },
    "& .featureDescTitle": {
        fontFamily: "Urbanis",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "28px",
        textAlign: "left",
        color: "#1E1E1E",
        "@media(max-width:960px)": {
            fontSize: "18px"
        }
    },
    "& .featureDesc": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "left",
        color: "#343D48",
        marginTop: "4px",
        paddingBottom: "16px",
        "@media(max-width:960px)": {
            fontSize: "14px"
        }
    },
    "& .descriptionTop": {
        marginTop: "36px"
    },
    "& .footerMainItemGrid": {
        padding: "50px 0px 52px 0px",
        "@media(max-width:899px)": {
            padding: "50px 0px 36px 0px",
        }
    },
    "& .featureTitleDescMainBox": {
        width: "70%",
        padding: "0px 30px"
    },
    "& .learnMore": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left",
        color: "#044352",
        display: "flex",
        alignItems: "center",
        textDecoration: "underline",
        textDecorationColor: "#044352",
        textDecorationThickness: "-0.5px",
        cursor: "pointer",
        "@media(max-width:960px)": {
            fontSize: "14px"
        }
    },
    "& .testimonialsItemMainBox": {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        overflow: "hidden",
        position: "relative",
        paddingBottom: "36px"
    },
    "& .testimonialCardDesc": {
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "30px",
        textAlign: "left",
        color: "#343D48",
        "@media(max-width:960px)": {
            fontSize: "14px"
        }
    },
    "& .cardUserMainBox": {
        marginTop: "30px",
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        bottom: 20
    },
    "& .userImageBox": {
        width: "45px",
        height: "45px",
        borderRadius: "50%",
        backgroundColor: "#4F96FF",
        overflow: "hidden"
    },
    "& .userImage": {
        width: "45px",
        height: "45px",
        objectFit: "cover",
        filter: "grayscale(100%)",
        transition: "filter 0.3s ease"
    },
    "& .userImage:hover": {
        filter: "grayscale(0%)"
    },
    "& .userIdText": {
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 500,
        textAlign: "left",
        color: "#4F96FF",
        "@media(max-width:960px)": {
            fontSize: "12px"
        }
    },
    "& .userIdName": {
        fontFamily: "DM Sans",
        fontSize: "17px",
        fontWeight: 500,
        textAlign: "left",
        color: "#343D48",
        "@media(max-width:960px)": {
            fontSize: "14px"
        }
    },
    "& .userNameIdBox": {
        marginLeft: "12px"
    },
    "& .userNameIdBoxArabic": {
        marginRight: "12px"
    },
    "& .cardDescBox": {
        minHeight: "200px",
        position: "relative",
        padding: "24px 40px 28px 36px",
        "@media(max-width:960px)": {
            padding: "24px 20px 28px 18px",
        },
        "&:hover": {
            padding: "24px 36px 28px 36px",
            "@media(max-width:960px)": {
                padding: "24px 18px 28px 18px",
            }
        }
    },
    "& .swiper-button-prev": {
        left: "5%",
        "@media(max-width:960px)": {
            left: "3%",
        }
    },
    "& .swiper-button-next": {
        right: "5%",
        "@media(max-width:960px)": {
            right: "3%",
        }
    },
    "& .swiper-slide": {
        display: "flex",
        justifyContent: "center",
        width: "fix-content"
    },
    "& .cardContainer": {
        display: "flex",
        width: "83.33%",
        overflowX: "auto",
        scrollSnapType: "x mandatory",
        scrollBehavior: "smooth",
        marginTop: "16px",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    "& .cardItem": {
        flex: "0 0 auto",
        scrollSnapAlign: "center",
        width: "98%",
        maxWidth: "480px",
        margin: "6px 0px",
        height: "fit-content",
        border: "1px solid rgba(0, 0, 0, 0.001)",
        "& .MuiPaper-root:hover": {
            boxShadow: "0px 13px 40px 0px rgba(75, 102, 135, 0.1)",
            border: "1px solid #4f96ff"
        },
        "@media(max-width:660px)": {
            width: "240px",
        }
    },
    "& .profileCircle": {
        width: "45px",
        height: "45px",
        borderRadius: "50%",
        backgroundColor: "#4F96FF",
    },
    "& .userDetails": {
        marginLeft: "12px",
    },
    "& .footerTypo": {
        fontFamily: "Urbanist",
        fontSize: "36px",
        fontWeight: 700,
        lineHeight: "44px",
        textAlign: "left",
        color: "#334155",
        "@media(max-width:1100px)": {
            fontSize: "32px",
        },
        "@media(max-width:960px)": {
            fontSize: "28px",
        },
        "@media(max-width:840px)": {
            fontSize: "24px",
        },
        "@media(max-width:720px)": {
            fontSize: "22px",
        },
        "@media(max-width:660px)": {
            fontSize: "20px",
            lineHeight: "36px"
        },
    },
    "& .footerCompanyGrid": {
        display: "flex"
    },
    "& .footerAboutUsBox": {
        marginTop: "56px"
    },
    "& .footerSignInText": {
        margin: "0px 0px 0px 8px",
        fontSize: "20px",
        fontWeight: 700,
        maxWidth: "245px",
        width: "80%",
        height: "60px",
        padding: "24px",
        backgroundColor: "#044352",
        color: "#FFFFFF",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352",
        },
        "@media(max-width:899px)": {
            fontSize: "18px",
            marginLeft: "0px"
        },
    },
    "& .footerSignInBox": {
        display: "flex",
        justifyContent: "flex-end",
        "@media(max-width:899px)": {
            justifyContent: "flex-start",
            marginTop: "24px"
        }
    },
    "& .footerAboutus": {
        fontFamily: "Urbanist",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "28px",
        textAlign: "left",
        marginBottom: "16px",
    },
    "& .footerAboutUsDesc": {
        fontFamily: "Urbanist",
        fonSsize: "14px",
        fontWeight: 400,
        lineHeight: "28px",
        textAlign: "left",
        color: "#52525B"
    },
    "& .footerCompanyLinks": {
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        textAlign: "left",
        marginTop: "10px",
        color: "#18181B",
        cursor: "pointer"
    },
    "& .learnDialogTitle": {
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "28px",
        margin: "8px 16px",
        color: "#1E1E1E"
    },
    "& .learnDialogClose": {
        position: 'absolute',
        top: 16,
        color: "#334155"
    },
    "& .termsDesc": {
        fontFamily: "Urbanist",
        padding: "20px 16px",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "26px",
        color: "#0F172A"
    },
    "& .termsCheckbox": {
        fontFamily: "Urbanist",
        paddingTop: "12px",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "26px",
        color: "#0F172A"
    },
    "& .termsActionButtonsBox": {
        paddingRight: "16px",
        margin: "24px 0px",
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .termsActionButtonsBoxArabic": {
        paddingLeft: "16px",
        margin: "24px 0px",
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .termsCancelButton": {
        padding: "16px 37.5px 16px 32.5px",
        width: "120px",
        height: "56px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        borderColor: "#F1F5F9",
        borderRadius: "8px",
        gap: "8px",
        opacity: "0px",
        background: "#F1F5F9",
        boxShadow: "0px 4px 4px 0px #00000040",
        color: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#F1F5F9",
            border: "1px solid #F1F5F9"
        },

    },
    "& .termsAgreeButton": {
        fontSize: "16px",
        fontWeight: 700,
        width: "120px",
        height: "56px",
        fontFamily: "Urbanist",
        padding: "16px 39.5px 16px 36.5px",
        gap: "8px",
        borderRadius: "8px",
        opacity: "0px",
        marginLeft: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352",
        },
    },
    "& .termsAgreeButtonArabic": {
        fontSize: "16px",
        fontWeight: 700,
        width: "120px",
        height: "56px",
        fontFamily: "Urbanist",
        padding: "16px 39.5px 16px 36.5px",
        gap: "8px",
        borderRadius: "8px",
        opacity: "0px",
        marginRight: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352",
        },
    }
})
export const MainWrapper = styled(Box)({
    width: "100vw",
    paddingBottom: "30px",
    "& .arabicDirection": {
        direction: "rtl",
    }
})
// Customizable Area End
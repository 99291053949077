Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.languageSettings = "Language Settings";
exports.manageLanguages="Manage your languages";
exports.headingNotificationPopup = "Changes Saved"; 
exports.titleNotificationPopup = "Notification Preferences Updated"; 
exports.subTitleNotificationPopup = "Your notification preferences have been successfully saved.";
exports.buttonNotificationText = "Done";
exports.headingNotificationPopup = "Changes Saved"; 
exports.titleNotificationPopup = "Notification Preferences Updated"; 
exports.subTitleNotificationPopup = "Your notification preferences have been successfully saved.";
exports.buttonNotificationText = "Done";
exports.headingLogoutProfilePopup = "Logout Confirmation"; 
exports.titleLogoutProfilePopup = "Are You Sure You Want to Logout?"; 
exports.subTitleLogoutProfilePopup = "You are about to log out. Any unsaved changes will be lost. Do you want to continue?";
exports.cancelButtonLogutProfilePopup = "Cancel"
exports.buttonLogoutProfileText = "Logout";
exports.headingDeleteAccountPopup = "Delete Account"; 
exports.titleDeleteAccountPopup = "Are You Sure You Want to Delete Account?"; 
exports.subTitleDeleteAccountPopup = "This action is permanent and cannot be undone. All your data will be deleted.";
exports.cancelButtonDeleteAccountPopup = "Cancel";
exports.checkBoxPopupText = "I understand the consequences and wish to proceed.";
exports.requiredCheckText = "Action Required";
exports.buttonDeleteAccountText = "Delete";
exports.headingDeleteSuccessPopup = "Account Deleted Successfully"; 
exports.subTitleDeleteSuccessPopup = "Your account has been successfully deleted. You will no longer have access to the platform.";
exports.buttonDeleteSuccessText = "Done";
exports.saveButtonSetting = "Save Settings";
exports.headingChangePassword = "Change Password"
exports.headingChangePasswordPopup = "Change Password";
exports.titleChangePasswordPopup = "Current Password*";
exports.errorChangePasswordPopup = "Required field";
exports.titlenewPasswordPopup = "New Password*"
exports.errorNewPasswordPopup = "Required field";
exports.titleconfirmPasswordPopup = "Confirm New Password*"
exports.errorConfirmPasswordPopup = "Required field";
exports.cancelButtonChangePassword = "Cancel";
exports.buttonChangePasswordText = "Save Changes";
exports.resetPasswordEndPoint ="bx_block_forgot_password/passwords/change_password";
exports.forgotPasswordAPiContentType = "application/json";
exports.resetApiMethod = "PUT";
exports.httpGetMethod = "GET";
exports.getCurrentPassApiEndPoint= "/bx_block_settings/settings/validate_current_user_password?current_password"
exports.ChangedheadingPopup = "Password Changed";
exports.successTitleChanged = "Password Changed Successfully!.";
exports.subTitleChangedPopup = "For added security, we recommend logging in again.";
exports.buttonTextChanged = "Login";
exports.showUserProfileApiEndPoint = "bx_block_profile/profiles"
exports.validationDeleteApiMethodType = "DELETE"
exports.getUserAccountDeleteAPiEndPoint ="/bx_block_profile/profiles"
exports.deleteProfilePhotoApiEndpoint = "bx_block_profile/profiles"
exports.updateUserProfileApiEndpoint = "bx_block_profile/profiles";
exports.putApiMethodType = "PUT";
exports.languageheadingPopup = "Language Set Successfully";
exports.successlanguageTitleChanged = "Language Changed";
exports.sublanguageTitleChangedPopup = " You have successfully changed the language to English.";
exports.buttonlanguageTextChanged = "Done";
exports.languageChangeApiEndPoint ="/bx_block_settings/languages/id?language"
// Customizable Area End
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.messageError = "Your message has been shortened to 500 characters";
exports.firstNameError = "must be less than 100 character";
exports.requiredErrorMsg = "Please fill out all required fields";
exports.privacyErrorMsg = "Please agree to privacy policy";
exports.validEmailErrorMsg = "Please enter a valid email address";

exports.messageErrorArabic = "لقد تم اختصار رسالتك إلى 500 حرف";
exports.firstNameErrorArabic = "يجب أن يكون أقل من 100 حرف";
exports.requiredErrorMsgArabic = "يرجى ملء جميع الحقول المطلوبة";
exports.privacyErrorMsgArabic = "يرجى الموافقة على سياسة الخصوصية";
exports.validEmailErrorMsgArabic = "يرجى إدخال عنوان بريد إلكتروني صالح";

exports.postContactUSApiEndpoint = "bx_block_contact_us/contact_us";
exports.getCountryCodeApiEndPoint = "bx_block_contact_us/fetch_country";
exports.getContactTitleDescEndPoint = "bx_block_landingpage2/contact_us";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CandidateDashboardController extends BlockComponent<
    Props,
    S,
    SS
>
{

// Customizable Area Start
// Customizable Area End

constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
}

async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
async componentDidMount() {
    super.componentDidMount();

}
// Customizable Area End
}
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { SelectChangeEvent } from '@mui/material/Select';
import { getStorageData } from "framework/src/Utilities";

interface JobTitles {
  id?: string;
  attributes?: {
    id: string;
    job_title: string;
  }
}
interface JobTitleResponse {
  message?: string;
  error?: string;
  data?: JobTitles[];
}

interface CreateCompetency {
  message?: string;
  error?: string;
  data: {
    id: string;
    type: string;
    attributes: {
      id: string;
      job_description_id: string;
      competency_type: string;
      job_title: string;
      description: string;
      created_at: string;
      updated_at: string;
      chatgpt_response: string;
      file_url: string;
      file_filename: string;
      file_size: number;
    }
  },
  "meta": {
    "message": "Competency created successfully"
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  errormsg: string;
  selectedCompetencyType: string;
  competencyType: string[];
  compDescription: string;
  selectedFile: File | null;
  selectedFileBase64: string;
  fileError: string;
  uploadedFile: string | undefined;
  fileName: string | undefined;
  fileSize: string | undefined;
  isEdit: boolean;
  generateCompClicked: boolean;
  jobTitle: JobTitles[] | undefined;
  selectedJobTitle: string;
  selectedLanguage: string;
  openSuccessComp: boolean;
  exportProgress: number;
  progrssbarScreen: boolean;
  active: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfchatgptcompetencymapgenerationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  interval: number | undefined;
  getJobTitlesApiCallId: string = "";
  createCompetencyApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: "",
      errormsg: "",
      selectedCompetencyType: "",
      competencyType: ["Core Competency", "Basic Competency", "Competency"],
      compDescription: "",
      selectedFile: null,
      selectedFileBase64: "",
      fileError: "",
      uploadedFile: "",
      fileName: "",
      fileSize: "",
      isEdit: false,
      generateCompClicked: false,
      jobTitle: [],
      selectedJobTitle: "",
      selectedLanguage: "",
      openSuccessComp: false,
      exportProgress: 0,
      progrssbarScreen: false,
      active: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getJobTitlesApiCallId) {
        this.listJobTitlesResponse(responseJson)
      }
      if (apiRequestCallId === this.createCompetencyApiCallId) {
        this.createCompetencyResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    const language = await getStorageData("language") || "English";
    this.setState({ selectedLanguage: language });

    const signInResponse = await getStorageData("signInResponse");
    const parsedSignInResponse = JSON.parse(signInResponse)
    this.setState({
      token: parsedSignInResponse.meta?.token,
    })

    this.getJobTitlesApiCall()
  }

  listJobTitlesResponse = (responseJson: JobTitleResponse) => {
    if (responseJson && !responseJson.error) {
      this.setState({
        jobTitle: responseJson.data,
      })
    }
  }

  createCompetencyResponse = (responseJson: CreateCompetency) => {
    if (responseJson && !responseJson.error) {

      clearInterval(this.interval);

      this.setState({
        exportProgress: 100,
      }, () => {
        this.setState({
          openSuccessComp: true,
        })
      })
    }
  }

  getJobTitlesApiCall = () => {
    const headers = {
      "token": this.state.token
    };
    const getJobTitleMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobTitlesApiCallId = getJobTitleMsg.messageId;
    getJobTitleMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getJobTitleMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getJobTitlesApiEndPoint}`
    );
    getJobTitleMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getJobTitleMsg.id, getJobTitleMsg);
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if (prevState.progrssbarScreen !== this.state.progrssbarScreen) {
      if (this.state.progrssbarScreen) {
        this.interval = setInterval(() => {
          if (this.state.active === 6) {
            this.setState({ active: 0 })
          } else {
            this.setState({ active: this.state.active + 1 })
          }
        }, 300) as unknown as number
      }
    }
  }

  handleGenerateComp = () => {
    this.setState({ generateCompClicked: true })
    const errorInCompType = !this.state.selectedCompetencyType;
    const errorInJobTitle = !this.state.selectedJobTitle;
    const errorInFile = (!this.state.isEdit && !(this.state.selectedFile || this.state.compDescription)) ||
      (this.state.isEdit &&
        (((this.state.uploadedFile || "").length < 1 && !this.state.selectedFile) || !this.state.compDescription))

    if (errorInCompType || errorInJobTitle || errorInFile) {
      return
    } else {
      this.setState({ progrssbarScreen: true, exportProgress: 0 }, () => {
        setTimeout(() => this.createCompetencyApiCall(), 4000);
        this.incrementProgress();
      });
    }
  }

  createCompetencyApiCall = () => {
    const headers = {
      token: this.state.token,
    };

    let formdata = new FormData();
    formdata.append("competency[competency_type]", this.state.selectedCompetencyType);
    formdata.append("competency[job_title]", this.state.selectedJobTitle);
    formdata.append("competency[description]", this.state.compDescription);
    formdata.append("competency[job_description_id]", this.state.selectedJobTitle);
    if (this.state.selectedFile) {
      formdata.append("competency[file]", this.state.selectedFile);
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createCompetencyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createCompetencyApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  incrementProgress = () => {
    const progressInterval = setInterval(() => {
      if (this.state.exportProgress >= 90) {
        clearInterval(progressInterval);
      }
      this.setState({ exportProgress: Math.min(this.state.exportProgress + 10, 90) });
    }, 500);
  }

  handleCompDescription = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ compDescription: event.target.value.length > 5000 ? this.state.compDescription : event.target.value })
  }

  handleCompetencyTypeChange = (event: SelectChangeEvent<string>) => {
    this.setState({ selectedCompetencyType: event.target.value });
  }

  handleJobTitleChange = (event: SelectChangeEvent<string>) => {
    this.setState({ selectedJobTitle: event.target.value });
  }
  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      const maxSizeInMB = 5;
      const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      if (!allowedTypes.includes(selectedFile.type) || (selectedFile.size > maxSizeInMB * 1024 * 1024)) {
        this.setState({ fileError: configJSON.fileErrorText })
        return;
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result as string;
          this.setState({ selectedFile, selectedFileBase64: base64String, fileError: "" });
        };
        reader.readAsDataURL(selectedFile);
      }
    }
  };

  handleImageClick = () => {
    const fileInput = document.getElementById('upload-jd-input') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  handleFileDelete = () => {
    this.setState({ selectedFile: null, uploadedFile: "", fileName: "", fileSize: "", fileError: "" })
  }

  handleCloseSuccessComp = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ViewCompetency");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }

  navigateToDashboard = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "CompetencyDashboard");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }

  navigateToCreateComp = () => {
    this.setState({ progrssbarScreen: !this.state.progrssbarScreen })
  }

  handleCondition = (condition: boolean, truePart: string, falsePart: string) => {
    return condition ? truePart : falsePart
  }
  // Customizable Area End
}

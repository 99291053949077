import React from "react";

import {
    Box,
    // Customizable Area Start
    styled,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider
    // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { logoutImg } from "./assets";
// Customizable Area End

import EmailAccountLogoutController, {
    Props,
} from "./EmailAccountLogoutController";

export default class EmailAccountLogout extends EmailAccountLogoutController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <MainWrapper>
                <Dialog
                    open={true}
                    onClose={this.props.closeLogout}
                    PaperProps={{
                        sx: {
                            direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
                            overflow: 'hidden',
                            borderRadius: "8px 8px 32px 8px"
                        }
                    }}
                >
                    <DialogTitle>
                        <DialogWrapper>
                            <Typography className="logoutDialogTitle">
                                Logout Confirmation
                            </Typography>
                            <IconButton
                                data-test-id="close_logout"
                                aria-label="close"
                                onClick={this.props.closeLogout}
                                className="logoutDialogClose"
                                sx={{
                                    right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                                    left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogWrapper>
                    </DialogTitle>
                    <Divider sx={{ color: "#E2E8F0" }} />
                    <DialogContent>
                        <DialogWrapper>
                            <Box className="logoutImageBox">
                                <img src={logoutImg.default} alt="Success Image" height={89} width={89} />
                            </Box>
                        </DialogWrapper>
                        <Typography
                            sx={{
                                fontFamily: "Urbanist",
                                padding: "14px 34px",
                                fontSize: "20px",
                                fontWeight: 700,
                                lineHeight: "28px",
                                color: "#0F172A",
                                textAlign: "center"
                            }}>
                            Are You Sure You Want to Logout?
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "Urbanist",
                                padding: "0px 58px",
                                fontSize: "18px",
                                fontWeight: 400,
                                lineHeight: "26px",
                                color: "#0F172A",
                                textAlign: "center"
                            }}>
                            You are about to log out. Any unsaved changes will be lost. Do you want to continue?
                        </Typography>

                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <DialogWrapper>
                            <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "logoutActionButtonsBox" : "logoutButtonsBoxArabic"}`}>
                                <Button
                                    onClick={this.props.closeLogout}
                                    variant="outlined"
                                    data-test-id="logoutCancel"
                                    className="logoutCancelButton"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={this.handleLogout}
                                    variant="contained"
                                    color="primary"
                                    data-test-id="logout"
                                    className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "logoutButton" : "logoutButtonArabic"}`}
                                >
                                    Logout
                                </Button>
                            </Box>
                        </DialogWrapper>
                    </DialogActions>
                </Dialog>
            </MainWrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const DialogWrapper = styled(Box)({
    width: "100%",
    "& .logoutDialogClose": {
        position: 'absolute',
        top: 16,
        color: "#334155"
    },
    "& .logoutImageBox": {
        display: "flex",
        padding: "10px 0px",
        justifyContent: "center"
    },
    "& .logoutDialogTitle": {
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "32px",
        margin: "8px 16px",
        color: "#1E1E1E"
    },
    "& .logoutActionButtonsBox": {
        margin: "24px 0px",
        paddingRight: "16px",
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .logoutButtonsBoxArabic": {
        display: "flex",
        justifyContent: "flex-end",
        paddingLeft: "16px",
        margin: "24px 0px"        
    },
    "& .logoutCancelButton": {
        fontSize: "16px",
        fontWeight: 700,
        padding: "16px 37.5px 16px 32.5px",
        width: "120px",
        height: "56px",
        fontFamily: "Urbanist",
        borderColor: "#F1F5F9",
        borderRadius: "8px",
        gap: "8px",
        opacity: "0px",
        background: "#F1F5F9",
        boxShadow: "0px 4px 4px 0px #00000040",
        color: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#F1F5F9",
            border: "1px solid #F1F5F9"
        },

    },
    "& .logoutButton": {
        borderRadius: "8px",
        fontSize: "16px",
        fontWeight: 700,
        width: "120px",
        height: "56px",
        fontFamily: "Urbanist",
        padding: "16px 39.5px 16px 36.5px",
        gap: "8px",
        opacity: "0px",
        marginLeft: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352",
        },
    },
    "& .logoutButtonArabic": {
        height: "56px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        width: "120px",
        padding: "16px 39.5px 16px 36.5px",
        gap: "8px",
        borderRadius: "8px",
        opacity: "0px",
        marginRight: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352",
        },
    },
    "& .errorClass": {
        border: '1px solid #F87171 !important',
    },
    "& .cultureInputField": {
        boxShadow: "0px 1px 2px 0px #1018280D",
        fontSie: "16px",
        fontFamily: 'Urbanist',
        border: '1px solid #CBD5E1',
        fontWeight: 400,
        lineHight: "24px",
        borderRadius: "8px",
        height: "98px",
        padding: "12px 16px",
        width: '100%',
        color: "#475569",
        "&:focus": {
            borderColor: "#CBD5E1",
            outline: "none",
        }
    },
    "& .companyCultureLimit": {
        display: "flex",
        justifyContent: "flex-end",
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: "Urbanist",
    },
    "& .dropDownStyle": {
        backgroundColor: 'white',
        height: '66px',
        borderRadius: '8px',
        border: '1px solid #CBD5E1',
        fontSie: '16px',
        fontWeight: 700,
        lineHight: "24px",
        fontFamily: 'Urbanist',
        padding: '10px 12px',
        width: '100%',
        color: "#475569"
    },
    "& .dropDownStyleplaceHolder": {
        color: "#DCDCDC",
        fontSize: 16,
        fontWeight: 400
    } as React.CSSProperties,

    "& .sumbmitBtn": {
        height: "65px",
        padding: "10px 16px 10px 16px",
        borderRadius: "4px 0px 0px 0px",
        background: "#044352",
        width: "100%"
    },
    "& .sumbmitText": {
        fontFamily: "Urbanist",
        fontFize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textTransform: "none",
        color: "#FFFFFF"

    },
    "& input::placeholder, textarea::placeholder": {
        color: "#cccccc"
    }
})
export const MainWrapper = styled(Box)({
    width: "100vw",
})
// Customizable Area End
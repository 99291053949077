export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const backArrow = require("../assets/backArrow.svg");
export const uploadImage = require("../assets/uploadImage.svg");
export const more = require("../assets/more.svg");
export const uploadedFile = require("../assets/uploadedFile.png");
export const emptyDashboardImg = require("../assets/emptyDashboardImg.png");
export const successImg = require("../assets/successImg.png");

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfchatgptcompetencymapgeneration";
exports.labelBodyText = "cfchatgptcompetencymapgeneration Body";

exports.btnExampleTitle = "CLICK ME";

exports.errorText = "This field is required";
exports.fileErrorText = "You can upload word and pdf files up to 5 mb's.";
exports.uploadJdText = "Upload your JD document";
exports.uploadJdSizeText = "You can upload word and pdf files up to 5 MB's";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.getJobTitlesApiEndPoint = "bx_block_dashboardguests/job_descriptions"
exports.createCompetencyApiEndPoint = "bx_block_cfchatgptcompetencymapgeneration/competencies"

// Customizable Area End